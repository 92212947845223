import { type TimetableHeader } from 'src/models/timetableHeader'
import { type ActivitySummary, type HeadCountByLabel } from 'src/models/progressBoard'
import { addDays } from 'date-fns'

type Params = {
  timetableHeader: TimetableHeader
  activitySummaries: ActivitySummary[]
}

function createActivitySummaryFilterCallback(timetableHeader: TimetableHeader): (activitySummary: ActivitySummary) => boolean {
  return (activitySummary: ActivitySummary) => {
    // TODO: 互換性実装、2つのearly returnまでLB側のデータ移行完了後に削除
    const isWithTypeAndMatch = timetableHeader
      .timetable_master
      .timetable_master_logimeter_activities
      .some((v) => (
        activitySummary.appropriation_type !== null &&
        activitySummary.activity_master_id === v.activity_master_id &&
        activitySummary.appropriation_type === v.appropriation_type
      ))
    if (isWithTypeAndMatch) {
      return true;
    }
    if (
      timetableHeader.timetable_master.appropriation_dt_diff_start === null ||
      timetableHeader.timetable_master.appropriation_dt_diff_end === null ||
      activitySummary.appropriation_date_start == null ||
      activitySummary.appropriation_date_end == null
    ) {
      return false;
    }

    const appropriation_dt_diff_start = addDays(timetableHeader.dt, timetableHeader.timetable_master.appropriation_dt_diff_start)
    const appropriation_dt_diff_end = addDays(timetableHeader.dt, timetableHeader.timetable_master.appropriation_dt_diff_end)

    return appropriation_dt_diff_start.getDate() === activitySummary.appropriation_date_start.getDate() &&
    appropriation_dt_diff_end.getDate() === activitySummary.appropriation_date_end.getDate()
  }
}

function replaceAt<T>(array: T[], index: number, value: T): T[] {
  return [...array.slice(0, index), value, ...array.slice(index + 1)]
}

function accumulateHeadCountByStaffLabelId(headCountsByLabel: HeadCountByLabel[], headCountByLabel: HeadCountByLabel): HeadCountByLabel[] {
  const index = headCountsByLabel.findIndex((v) => v.staff_label_id === headCountByLabel.staff_label_id)

  // staff_label_idが一致するものがなかったため末尾に追加
  if (index === -1) {
    return [...headCountsByLabel, headCountByLabel]
  }

  // 順番を維持したいので置換する
  return replaceAt(headCountsByLabel, index, {
    ...headCountsByLabel[index],
    head_count: headCountsByLabel[index].head_count + headCountByLabel.head_count,
  })
}

function accumulateHeadCountAsOtherFromThird(headCountsByLabel: HeadCountByLabel[], headCountByLabel: HeadCountByLabel): HeadCountByLabel[] {
  if (headCountsByLabel.length < 2) {
    return [...headCountsByLabel, headCountByLabel]
  }

  // 3項目目以降は「他」として合算する
  return replaceAt(headCountsByLabel, 2, {
    staff_label_id: -1,
    staff_label_name: '他',
    head_count: (headCountsByLabel[2]?.head_count ?? 0) + headCountByLabel.head_count,
    disp_color: '',
  })
}

export function getHeadCountsByLabel({ timetableHeader, activitySummaries }: Params): HeadCountByLabel[] {
  return activitySummaries
    .filter(createActivitySummaryFilterCallback(timetableHeader))
    .flatMap((v) => v.head_counts_by_label)
    .reduce(accumulateHeadCountByStaffLabelId, [] as HeadCountByLabel[])
    .filter((v) => v.head_count > 0)
    .reduce(accumulateHeadCountAsOtherFromThird, [] as HeadCountByLabel[])
}
