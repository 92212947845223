













import { defineComponent, PropType, ref, toRefs, watch } from '@vue/composition-api';
import { useHeadcountUpdatePopover } from '../composables/useHeadcountUpdatePopover';
import { DailySimulationTimeBlock } from '../types';

export default defineComponent({
  props: {
    timeBlock: {
      type: Object as PropType<DailySimulationTimeBlock>,
      required: true,
    },
    timetableName: {
      type: String as PropType<string | null>,
      default: null,
    },
    isBreakTime: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const headcountElement = ref<HTMLDivElement>();
    const { timeBlock, isBreakTime } = toRefs(props);
    const { timeBlock: activeTimeBlock, showHeadcountUpdatePopover } = useHeadcountUpdatePopover();

    const onClick = (event: MouseEvent) => {
      if (!timeBlock.value.isSimulated || isBreakTime.value) {
        return;
      }
      showHeadcountUpdatePopover({
        event,
        timeBlock: timeBlock.value,
        timetableName: props.timetableName,
      });
      headcountElement.value?.classList.add('is-target');
    };

    watch(
      () => activeTimeBlock.value,
      () => {
        if (
          activeTimeBlock.value?.timetableMasterId !== timeBlock.value.timetableMasterId ||
          activeTimeBlock.value?.displayTime !== timeBlock.value.displayTime
        ) {
          headcountElement.value?.classList.remove('is-target');
        }
      },
    );

    return {
      headcountElement,
      onClick,
    };
  },
});
