
import { defineComponent, computed, inject, ComputedRef, watch } from '@vue/composition-api'
import ShiftTableHeader from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTable/ShiftTableHeader/index.vue'
import ShiftTableMain from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTable/ShiftTableMain/index.vue'
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { DisplayConditionStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition'
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState'
import { Total7Days } from 'src/models/regularShift'
import { filterStaffForDisplay } from 'src/views/Dashboard/Workplace/RegularShift/libs/filterStaffHelper'
import { calc7daysTotal } from 'src/views/Dashboard/Workplace/RegularShift/libs/calcTotalShiftHelper'
import { getShiftDisplayContent } from 'src/views/Dashboard/Workplace/RegularShift/libs/shiftDisplayHelper'
import { useCreateShiftCsvTemplate } from '../../hooks/useCreateShiftCsvTemplate'

export default defineComponent({
  name: 'shift-table',

  components: {
    ShiftTableHeader,
    ShiftTableMain,
  },

  setup() {
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const displayConditionState = inject(DisplayConditionStateKey);
    const commonState = inject(CommonStateKey);
    if (!staffWithShiftsState || !displayConditionState || !commonState) {
      throw new Error('State error');
    }

    const {
      staffsWithShifts,
      shouldShowDisabled,
      shiftChangeFlag,
    } = staffWithShiftsState

    const {
      displayMode,
    } = commonState

    const total7Days: ComputedRef<Total7Days> = computed(() => calc7daysTotal(staffsWithShifts.value, displayMode.value))

    watch(() => {
      return [
        displayConditionState,
        shouldShowDisabled.value,
        shiftChangeFlag.value,
      ]
    }, () => {
      // 表示条件によるフィルター
      staffsWithShifts.value.forEach((e) => {
        e.should_show = filterStaffForDisplay({
          shouldShowDisabled: shouldShowDisabled.value,
          displayConditionState: displayConditionState,
          staffWithShifts: e,
          total7Days: total7Days.value,
          displayMode: displayMode.value,
        })
      })
    }, { deep: true, immediate: true })

    watch(() => {
      return [displayMode.value]
    }, () => {
      staffsWithShifts.value.forEach((e) => {
        e.shifts = getShiftDisplayContent(e.orig_shifts, e.orig_pre_shifts, displayMode.value);
      })
    })

    const { staffWithShifts } = useCreateShiftCsvTemplate()

    watch(
      staffsWithShifts,
      (staffsWithShifts) => {
        staffWithShifts.value = staffsWithShifts.filter((e) => {
          return e.should_show
        })
      },
      {
        immediate: true,
      }
    )

    return {
      staffsWithShifts,
      total7Days,
    }
  }
})
