type MockSkillResponse = {
  id: number
  name: string
}

export type MockStaffResponse = {
  id: number
  family_name: string
  family_name_kana: string
  first_name: string
  first_name_kana: string
  progress_id: number
  staff_type: number
  sex: number
  is_new_employee: boolean
  is_key_player: boolean
  start_time: number
  end_time: number
  break_time: number
  skills: MockSkillResponse[]
}

export type MockProgressResponse = {
  id: number
  name: string
  disp_color: string
  current_time: number
  target_time?: number | null
  result_amount_sum: number
  scheduled_amount_sum: number
  result_headcount_sum: number
  scheduled_headcount_sum: number
  planned_productivity: number
  is_prior?: boolean
}

export const mockSkills: MockSkillResponse[] = [
  { id: 1, name: '検品梱包' },
  { id: 2, name: 'オーダーピッキング' },
  { id: 3, name: 'トータルピッキング' },
  { id: 4, name: '入荷受付' },
  { id: 5, name: '棚入れ' },
  { id: 6, name: '仕分け' },
  { id: 7, name: '加工' },
]

const staffs: MockStaffResponse[] = []
// オーダーピッキング
staffs.push(createMockStaff({ name: '水島 ゆかり', kana: 'みずしま ゆかり', progress_id: 1, sex: 1 }))
staffs.push(createMockStaff({ name: '鈴木 一郎', kana: 'すずき いちろう', progress_id: 1, start_time: 130000 }))
staffs.push(createMockStaff({ name: '坂本 太郎', kana: 'さかもと たろう', progress_id: 1, start_time: 100000 }))
staffs.push(createMockStaff({ name: '西戸 太郎', kana: 'にしど たろう', progress_id: 1 }))
staffs.push(createMockStaff({ name: '新田 太郎', kana: 'にった たろう', progress_id: 1 }))
staffs.push(createMockStaff({ name: '水谷 太郎', kana: 'みずたに たろう', progress_id: 1, staff_type: 1 }))
staffs.push(createMockStaff({ name: '斎藤 雄介', kana: 'さいとう ゆうすけ', progress_id: 1, is_new_employee: true, is_key_player: true, end_time: 170000 }))
staffs.push(createMockStaff({ name: '西 太郎', kana: 'にし たろう', progress_id: 1, is_key_player: true }))
staffs.push(createMockStaff({ name: '江口 陽太', kana: 'にぐち ようた', progress_id: 1 }))
staffs.push(createMockStaff({ name: '水戸部 太郎', kana: 'みとべ たろう', progress_id: 1 }))

// トータルピッキング
staffs.push(createMockStaff({ name: '坂口 太郎', kana: 'さかぐち たろう', progress_id: 2 }))
staffs.push(createMockStaff({ name: '藤堂 太郎', kana: 'ふじどう たろう', progress_id: 2 }))
staffs.push(createMockStaff({ name: '福田 太郎', kana: 'ふくだ たろう', progress_id: 2 }))
staffs.push(createMockStaff({ name: '西条 太郎', kana: 'さいじょう たろう', progress_id: 2 }))
staffs.push(createMockStaff({ name: '轟 太郎', kana: 'とどろき たろう', progress_id: 2 }))
staffs.push(createMockStaff({ name: '近藤 太郎', kana: 'こんどう たろう', progress_id: 2 }))

// 仕分け
staffs.push(createMockStaff({ name: '小阪 太郎', kana: 'こさか たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '姫島 太郎', kana: 'ひめじま たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '小口 太郎', kana: 'おぐち たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '東坂 太郎', kana: 'とうさか たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '六方 太郎', kana: 'ろっぽう たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '坂島 太郎', kana: 'さかじま たろう', progress_id: 3 }))
staffs.push(createMockStaff({ name: '佐藤 太郎', kana: 'さとう たろう', progress_id: 3 }))

// 検品梱包
staffs.push(createMockStaff({ name: '赤部 太郎', kana: 'あかべ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '牟田 太郎', kana: 'むた たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '東郷 太郎', kana: 'とうごう たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '小野田 太郎', kana: 'おのだ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '唐津 太郎', kana: 'からつ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '近藤 太郎', kana: 'こんどう たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '結城 太郎', kana: 'ゆいしろ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '右京 太郎', kana: 'うきょう たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '新見 太郎', kana: 'にいみ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '広田 太郎', kana: 'ひろた たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '松本 太郎', kana: 'まつもと たろう', progress_id: 4, staff_type: 1 }))
staffs.push(createMockStaff({ name: '松田 太郎', kana: 'まつだ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '青山 太郎', kana: 'あおやま たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '向井 太郎', kana: 'むかい たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '内海 太郎', kana: 'うつみ たろう', progress_id: 4 }))
staffs.push(createMockStaff({ name: '森本 太郎', kana: 'もりもと たろう', progress_id: 4 }))

// 入荷受入
staffs.push(createMockStaff({ name: '相羽 太郎', kana: 'あいば たろう', progress_id: 5 }))
staffs.push(createMockStaff({ name: '青池 太郎', kana: 'あおいけ たろう', progress_id: 5 }))
staffs.push(createMockStaff({ name: '赤壁 太郎', kana: 'あかかべ たろう', progress_id: 5 }))
staffs.push(createMockStaff({ name: '秋谷 太郎', kana: 'あきたに たろう', progress_id: 5 }))
staffs.push(createMockStaff({ name: '芥川 太郎', kana: 'あくたがわ たろう', progress_id: 5 }))

// 棚入
staffs.push(createMockStaff({ name: '明石 太郎', kana: 'あかし たろう', progress_id: 6 }))
staffs.push(createMockStaff({ name: '浅井 太郎', kana: 'あさい たろう', progress_id: 6 }))
staffs.push(createMockStaff({ name: '飛鳥 太郎', kana: 'あすか たろう', progress_id: 6 }))
staffs.push(createMockStaff({ name: '穴倉 太郎', kana: 'あなくら たろう', progress_id: 6 }))

// 加工
staffs.push(createMockStaff({ name: '石蔵 太郎', kana: 'いしくら たろう', progress_id: 7 }))
staffs.push(createMockStaff({ name: '今田 太郎', kana: 'いまだ たろう', progress_id: 7 }))
staffs.push(createMockStaff({ name: '吉田 太郎', kana: 'よしだ たろう', progress_id: 7 }))
staffs.push(createMockStaff({ name: '関 太郎', kana: 'せき たろう', progress_id: 7 }))

function createMockStaff({ name, kana, progress_id, staff_type, sex, is_new_employee, is_key_player, start_time, end_time, break_time }:
  { name: string, kana: string, progress_id: number, staff_type?: number, sex?: number, is_new_employee?: boolean, is_key_player?: boolean, start_time?: number, end_time?: number, break_time?: number }) {
  const [family_name, first_name] = name.split(' ')
  const [family_name_kana, first_name_kana] = kana.split(' ')
  const skills = []
  for (let i = 0; i < mockSkills.length; i++) {
    if (Math.random() < 0.5) {
      skills.push(mockSkills[i])
    }
  }
  return {
    id: staffs.length + 1,
    family_name,
    family_name_kana,
    first_name,
    first_name_kana,
    progress_id,
    staff_type: staff_type ?? 0,
    sex: sex ?? 0,
    is_new_employee: is_new_employee ?? false,
    is_key_player: is_key_player ?? false,
    start_time: start_time ?? 90000,
    end_time: end_time ?? 180000,
    break_time: break_time ?? 10000,
    skills,
  }
}

const progresses: MockProgressResponse[] = []
progresses.push(createMockProgress({
  id: 1,
  name: 'オーダーピッキング',
  disp_color: '4472c4',
  result_amount_sum: 3200,
  scheduled_amount_sum: 4500,
  result_headcount_sum: 0,
  planned_productivity: 55,
  is_prior: true,
  target_time: 160000,
}))
progresses.push(createMockProgress({
  id: 2,
  name: 'トータルピッキング',
  disp_color: '4472c4',
  result_amount_sum: 4900,
  scheduled_amount_sum: 5100,
  result_headcount_sum: 30,
  planned_productivity: 180,
  is_prior: true,
  target_time: 140000,
}))
progresses.push(createMockProgress({
  id: 3,
  name: '仕分け',
  disp_color: '4472c4',
  result_amount_sum: 4000,
  scheduled_amount_sum: 5100,
  result_headcount_sum: 26.7,
  planned_productivity: 150,
  target_time: 160000,
}))
progresses.push(createMockProgress({
  id: 4,
  name: '検品梱包',
  disp_color: '4472c4',
  result_amount_sum: 1210,
  scheduled_amount_sum: 4000,
  result_headcount_sum: 30.9,
  planned_productivity: 40,
  is_prior: true,
  target_time: 173000,
}))
progresses.push(createMockProgress({
  id: 5,
  name: '入荷受付',
  disp_color: 'fa1e43',
  result_amount_sum: 2400,
  scheduled_amount_sum: 3000,
  result_headcount_sum: 20,
  planned_productivity: 100,
  is_prior: true,
  target_time: 173000,
}))
progresses.push(createMockProgress({
  id: 6,
  name: '棚入れ',
  disp_color: 'fa1e43',
  result_amount_sum: 2200,
  scheduled_amount_sum: 2800,
  result_headcount_sum: 25.9,
  planned_productivity: 80,
  target_time: 173000,
}))
progresses.push(createMockProgress({
  id: 7,
  name: '加工',
  disp_color: 'a5a5a5',
  result_amount_sum: 500,
  scheduled_amount_sum: 1000,
  result_headcount_sum: 11.1,
  planned_productivity: 40,
  target_time: 180000,
}))

// progresses.push(createMockProgress({
//   id: 7,
//   name: '集荷',
//   disp_color: 'fa1e43',
//   result_amount_sum: 6000,
//   scheduled_amount_sum: 8000,
//   result_headcount_sum: 100,
//   planned_productivity: 100,
//   target_time: 130000,
// }))
// progresses.push(createMockProgress({
//   id: 8,
//   name: '投入',
//   disp_color: 'a5a5a5',
//   result_amount_sum: 6000,
//   scheduled_amount_sum: 8000,
//   result_headcount_sum: 100,
//   planned_productivity: 100,
//   target_time: null,
// }))

function createMockProgress({ id, name, disp_color, result_amount_sum, scheduled_amount_sum, result_headcount_sum, planned_productivity, current_time, target_time, is_prior }:
  { id: number, name: string, disp_color: string, result_amount_sum: number, scheduled_amount_sum: number, result_headcount_sum: number, planned_productivity: number, current_time?: number, target_time?: number | null, is_prior?: boolean }) {
  return {
    id,
    name,
    disp_color,
    current_time: current_time ?? 140000,
    target_time: target_time === null ? null : (target_time ?? 180000),
    result_amount_sum,
    scheduled_amount_sum,
    result_headcount_sum,
    scheduled_headcount_sum: 0, // 利用しない
    planned_productivity,
    is_prior,
  }
}

export const getMockResponse = (budgetGroupId: number | null) => {
  return {
    progresses,
    staffs,
  }
}
