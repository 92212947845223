



















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { useTruncatedText } from 'src/composables/useTruncatedText';
import { addComma } from 'src/filters/number_filters';
import { formatTimeInteger } from 'src/util/datetime';

export default defineComponent({
  filters: {
    formatNumber: addComma,
    formatTime: (v: number) => formatTimeInteger(v, 'HH : MM'),
  },
  props: {
    timetableName: {
      type: String as PropType<string | null>,
      default: null,
    },
    startTime: {
      type: Number,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    headcountFrom: {
      type: Number,
      required: true,
    },
    headcountTo: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const timetableName = computed(() => props.timetableName ?? '');
    const { elementRef } = useTruncatedText({ text: timetableName });

    return {
      elementRef,
    };
  },
});
