import { packToTimeInteger, timeDifferenceInSeconds, unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID } from './const';
import {
  ProgressHistory,
  DailySimulationHeader,
  DailySimulationTimetableRow,
  DailySimulationHourBlock,
  DailySimulationTimeBlock,
  TimetableMasterWithProgressHeader,
} from './types';
import { DeepReadonly, computed, reactive } from '@vue/composition-api';
import { ProgressHeader } from 'src/models/progressHeader';
import { ProgressHeadcountDetail } from 'src/models/progressHeadcountDetail';
import { ProgressDetail } from 'src/models/progressDetail';
import { addComma } from 'src/filters/number_filters';
import { TimetableMasterMap } from 'src/composables/useTimetableMasters';
import { TimetableMaster } from 'src/models/timetableMaster';

const DEFAULT_BLOCK_LENGTH_PER_HOUR = 4;

/**
 * 1 時間内のブロック数を計算して返す
 */
export const getBlockLengthPerHour = (progressHeaders: DeepReadonly<ProgressHeader[]>) => {
  for (const progressHeader of progressHeaders) {
    for (const { start_time, end_time } of progressHeader.progress_headcount_details) {
      if (!isExist(start_time) || !isExist(end_time) || start_time === 0 || end_time === 0) {
        continue;
      }
      return Math.round((60 * 60) / timeDifferenceInSeconds(start_time, end_time));
    }
  }
  return DEFAULT_BLOCK_LENGTH_PER_HOUR;
};

/**
 * API のレスポンスから工程情報を生成する
 */
export const createTimetableFromApiResponse = (
  progressHeaders: DeepReadonly<ProgressHeader[]>,
  displayHourPeriod: number,
  timetableMasterMap: TimetableMasterMap,
): DailySimulationTimetableRow[] => {
  const blockLengthPerHour = getBlockLengthPerHour(progressHeaders);
  return progressHeaders
    .concat()
    .map((progressHeader) => {
      const header = createHeaderFromProgressHeader(progressHeader, timetableMasterMap);
      const hourBlocks = createHourBlocksFromHeadcountDetails(
        progressHeader.progress_headcount_details,
        blockLengthPerHour,
        displayHourPeriod,
        progressHeader.timetable_master_id,
      );
      const { progressHistories } = createProgressHistoriesFromProgressDetails(progressHeader.progress_details);
      const timetableRow: DailySimulationTimetableRow = reactive({
        header,
        progressHistories,
        hourBlocks,
        progressHeader,
      });
      return timetableRow;
    })
    .sort(({ header: a }, { header: b }) => (a.dispOrder ?? 0) - (b.dispOrder ?? 0));
};

/**
 * progressHeader.progress_headcount_details から lastHeadcountExistTime を算出して返却する
 */
export const getLastHeadcountExistTime = (
  progressHeaders: DeepReadonly<ProgressHeader[]>,
  displayHourPeriod: number,
): number => {
  const blockLengthPerHour = getBlockLengthPerHour(progressHeaders);
  return progressHeaders.reduce((lastHeadcountExistTime, progressHeader) => {
    const progressHeadcountDetails = progressHeader.progress_headcount_details;
    let _lastHeadcountExistTime = 0;
    for (let hour = 0; hour < displayHourPeriod; hour++) {
      for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
        const targetStartTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
        const targetBlock = progressHeadcountDetails.find(
          (planBoardBlock) => planBoardBlock.start_time === targetStartTime,
        );
        if (isExist(targetBlock)) {
          _lastHeadcountExistTime = targetBlock.end_time;
        }
      }
    }
    return Math.max(lastHeadcountExistTime, _lastHeadcountExistTime);
  }, 0);
};

/**
 * TimelineIndexResult を View Model に変換する公開メソッドから呼ばれる内部メソッド。
 * TimelineIndexResult にある progress_headers 配列の 1 要素を引数に取り、
 * シミュレーションに利用する情報群 (DailySimulationHeader) を生成する。
 */
const createHeaderFromProgressHeader = (
  progressHeader: DeepReadonly<ProgressHeader>,
  timetableMasterMap: TimetableMasterMap,
): DailySimulationHeader => {
  const timetableMaster = timetableMasterMap[progressHeader.timetable_master_id];
  const header: DailySimulationHeader = reactive({
    masterId: timetableMaster?.id ?? null,
    name: timetableMaster?.name ?? null,
    backgroundColor: timetableMaster?.disp_color,
    dispOrder: timetableMaster?.disp_order ?? null,
    scheduledQuantity: progressHeader.scheduled_quantity,
    totalProductivity: progressHeader.total_productivity,
    targetProductivity: progressHeader.target_productivity,
    needManHours: computed(() => {
      if (!isExist(header.scheduledQuantity) || !isExist(header.targetProductivity)) {
        return null;
      }
      return header.scheduledQuantity / header.targetProductivity;
    }),
    targetCompletionTime: progressHeader.target_completion_time,
    simulatedCompletionTime: null,
    planedProductivity: timetableMaster?.planned_productivity ?? null,
  });
  return header;
};

/**
 * TimelineIndexResult を View Model に変換する公開メソッドから呼ばれる内部メソッド。
 * TimelineIndexResult にある progress_headers 配列の 1 要素にある progress_headcount_details(人時情報の配列) を引数に取り、
 * 1 時間毎に人時情報の配列をまとめた配列を生成する。
 */
const createHourBlocksFromHeadcountDetails = (
  progressHeadcountDetails: DeepReadonly<ProgressHeadcountDetail[]>,
  blockLengthPerHour: number,
  displayHourPeriod: number,
  timetableMasterId: number,
) => {
  const hourBlocks: DailySimulationHourBlock[] = [];
  for (let hour = 0; hour < displayHourPeriod; hour++) {
    const unitTimeBlocks: DailySimulationTimeBlock[] = [];
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetStartTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
      const targetBlock = progressHeadcountDetails.find(
        (planBoardBlock) => planBoardBlock.start_time === targetStartTime,
      );
      const headcount = targetBlock?.headcount ?? 0;
      unitTimeBlocks.push({
        displayTime: unpackTimeIntegerToStringFormat(targetStartTime),
        headcount,
        timetableMasterId,
        isSimulated: false,
      });
    }
    hourBlocks.push({
      unitTimeBlocks,
    });
  }
  return hourBlocks;
};

/**
 * TimelineIndexResult を View Model に変換する公開メソッドから呼ばれる内部メソッド。
 * TimelineIndexResult にある progress_headers 配列の 1 要素にある progress_details(進捗履歴の配列) を引数に取り、
 * 進捗履歴情報を生成する。
 */
const createProgressHistoriesFromProgressDetails = (progressDetails: DeepReadonly<ProgressDetail[]>) => {
  const progressHistories: ProgressHistory[] = [];
  let totalQuantity = 0;
  let lastEndTime = 0;

  progressDetails
    .concat()
    .sort((a, b) => {
      return a.start_time - b.start_time;
    })
    .forEach((data) => {
      const startTime = data.start_time;
      const endTime = data.end_time;

      if (data.start_time !== lastEndTime) {
        progressHistories.push({
          quantity: 0,
          totalQuantity,
          productivity: 0,
          startTime: lastEndTime,
          endTime: startTime,
          isBlankCell: true,
        });
      }

      totalQuantity += data.quantity;
      progressHistories.push({
        quantity: data.quantity ?? 0,
        totalQuantity,
        productivity: data.quantity / data.man_hours,
        startTime: data.start_time,
        endTime,
      });
      lastEndTime = data.end_time;
    });
  return {
    progressHistories,
  };
};

/**
 * 当日シミュレーションの timetable から、単位時間ごとの合計人時を 1 時間ごとの配列で持つ配列を生成する
 */
export const createTotalHourBlocksFromDailySimulationTimetable = (
  timetable: DailySimulationTimetableRow[],
  displayHourPeriod: number,
) => {
  const blockLengthPerHour = timetable[0]?.hourBlocks[0]?.unitTimeBlocks.length ?? DEFAULT_BLOCK_LENGTH_PER_HOUR;
  const totalHourBlocks = createInitialHourBlocks(
    blockLengthPerHour,
    displayHourPeriod,
    DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID,
  );
  timetable.forEach((timetableRow) => {
    timetableRow.hourBlocks.forEach(({ unitTimeBlocks }, hour) => {
      unitTimeBlocks.forEach((headcountData, timeBlockIndex) => {
        if (headcountData.isSimulated) {
          return;
        }
        totalHourBlocks[hour].unitTimeBlocks[timeBlockIndex].headcount += headcountData.headcount;
      });
    });
  });
  return totalHourBlocks;
};

/**
 * hourBlocks の初期状態を生成する
 */
export const createInitialHourBlocks = (
  blockLengthPerHour: number,
  displayHourPeriod: number,
  timetableMasterId: number,
): DailySimulationHourBlock[] => {
  return new Array(displayHourPeriod).fill('').reduce((hourBlocks, _, hour) => {
    const unitTimeBlocks: DailySimulationTimeBlock[] = new Array(blockLengthPerHour)
      .fill('')
      .map((_, timeBlockIndex) => ({
        displayTime: convertHourStr(hour, (timeBlockIndex * 60) / blockLengthPerHour),
        headcount: 0,
        timetableMasterId,
        isSimulated: false,
      }));
    hourBlocks.push({
      unitTimeBlocks,
    });
    return hourBlocks;
  }, []);
};

export const formatCommaValue = (_value?: number, numberOfDecimalPlaces: number = 0, defaultValue: string = '') => {
  if (!isExist(_value) || _value.toString().length === 0) {
    return defaultValue;
  }
  const denominator = Math.pow(10, numberOfDecimalPlaces);
  const value = Math.round(_value * denominator);
  // value が -0 だった場合に、addComma(-0) の結果 '-0' という文字列に変換されてしまうのを避けるため value が 0(-0) のときは 0 という三項演算子にしている
  const commaValue = addComma(value === 0 ? 0 : value / denominator);
  return commaValue.includes('.') || numberOfDecimalPlaces === 0 ? commaValue : `${commaValue}.0`;
};

const convertHourStr = (hour: number, min: number) => {
  return `${hour}:${`${min}`.padEnd(2, '0')}`;
};

export const getTimetableMastersWithProgressHeader = (
  timetableMasters: TimetableMaster[],
  progressHeaders: DeepReadonly<ProgressHeader[]>,
): TimetableMasterWithProgressHeader[] => {
  return timetableMasters.map((timetableMaster) => {
    const progressHeader = progressHeaders.find(
      (progressHeader) => progressHeader.timetable_master_id === timetableMaster.id,
    );
    return {
      ...timetableMaster,
      progressHeader: progressHeader,
    };
  });
};
