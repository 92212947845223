
import { defineComponent, SetupContext, reactive, PropType } from '@vue/composition-api'
import { isExist } from 'src/util/isExist'
import { ProgressEx } from '../index.vue'
import moment from 'src/util/moment-ja'

type SimulationModalProgress = {
  name: string
  color: string
  predictedCompleteTime: string
  targetTime: string
  diffTimeMinutes: number | null
  isOverTargetTime: boolean
}

export type SimulationModalState = {
  isVisible: boolean
  progresses: SimulationModalProgress[]
}

export const simulationModalHooks = () => {
  const state: SimulationModalState = reactive({
    isVisible: false,
    progresses: [],
  })

  const openSimulationModal = (progresses: ProgressEx[], targetDate: Date | null): void => {
    if (!isExist(targetDate)) { return }
    const baseMoment = moment(targetDate).startOf('day')
    state.isVisible = true
    const _progresses = progresses.map(progress => {
      const currentTime = progress.time.current
      const targetTime = progress.time.target
      const productivity = progress.isUseCurrentProductivity ? progress.productivity.current : progress.productivity.planned
      const productivityPerHour = (productivity ?? 0) * progress.currentHeadCount
      const predictedCompleteTime = productivityPerHour === 0 ? null : currentTime.clone().add(progress.amount.remaining / productivityPerHour, 'hour')
      const diffTimeMinutes = (Number.isNaN(targetTime.unix()) || !isExist(predictedCompleteTime)) ? null : targetTime.diff(predictedCompleteTime, 'minute')
      return {
        name: progress.name,
        color: progress.color,
        predictedCompleteTime: !isExist(predictedCompleteTime) ? '-' : getRelativeTime(predictedCompleteTime, baseMoment),
        targetTime: Number.isNaN(targetTime.unix()) ? '-' : getRelativeTime(targetTime, baseMoment),
        diffTimeMinutes,
        isOverTargetTime: !isExist(diffTimeMinutes) ? false : diffTimeMinutes < 0,
      }
    })
    state.progresses = _progresses.sort((a, b) => (b.diffTimeMinutes ?? 0) - (a.diffTimeMinutes ?? 0))
  }

  const getRelativeTime = (endTime: moment.Moment, startTime: moment.Moment) => {
    const totalMinutes = endTime.diff(startTime, 'minute')
    const hour = Math.floor(totalMinutes / 60)
    const minute = Math.floor(totalMinutes % 60)
    return `${hour > 100 ? `${hour}` : `0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}`
  }

  const closeSimulationModal = (): void => {
    state.isVisible = false
  }

  return {
    state,
    openSimulationModal,
    closeSimulationModal,
  }
}

export default defineComponent({
  name: 'assignment-simulation-modal',
  props: {
    state: {
      type: Object as PropType<SimulationModalState>,
      default: null,
    },
  },
  setup(props, context: SetupContext) {
    const closeSimulationModal = (): void => {
      context.emit('close')
    }
    return {
      closeSimulationModal,
    }
  },
})

