









import { defineComponent, reactive, SetupContext, computed, PropType } from '@vue/composition-api'

interface State {
  sortIconClass: string
}

// TODO: イマイチなのでlogimeterにあるSorter2に切り替えたい
export default defineComponent({
  props: {
    sortTarget: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    sortKey: {
      type: String,
      default: 'id',
    },
    toggleClass: {
      type: String,
      default: '',
    },
    sortFunc: {
      type: Function as PropType<(sortTarget: any, sortKey: string) => number>
    },
  },
  setup(props, context: SetupContext) {
    const state: State = reactive({
      sortIconClass: computed(() => {
        const sortObjects: { key: string, asc: boolean, forceOrder: boolean}[] | null = props.sortTarget.sortObjects
        if (sortObjects === null) { return 'fa-sort' }
        const sortObject: { key: string, asc: boolean, forceOrder: boolean } | undefined =
          sortObjects.find(e => e.key === props.sortKey)
        if (sortObject) {
          if (sortObject.asc) {
            return 'fa-sort-up'
          } else {
            return 'fa-sort-down'
          }
        } else {
          return 'fa-sort'
        }
      })
    })

    function sortBy(key: string) {
      props.sortTarget.sortBy([{ key: key, asc: true, forceOrder: false }])
      if (props.sortFunc) {
        props.sortFunc(props.sortTarget, props.sortKey)
      }
    }

    return {
      state,
      sortBy,
    }
  }
})
