



















import { defineComponent, ref, computed, watch, type PropType } from '@vue/composition-api';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { type TimeInteger } from 'src/models/common';
import TimeDisplay from './TimeDisplay.vue';

export default defineComponent({
  components: {
    TimeInput,
    TimeDisplay,
  },
  props: {
    value: {
      type: Number as PropType<TimeInteger | null>,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const valueRef = ref<TimeInteger | null>(props.value);
    const hasError = computed(() => Number.isNaN(valueRef.value));
    const hasFocus = ref(false);
    const focusHoursOnMount = ref(false);
    const focusMinutesOnMount = ref(false);
    const handleFocusTimeDisplay = (e: 'hours' | 'minutes') => {
      hasFocus.value = true;
      focusHoursOnMount.value = e === 'hours';
      focusMinutesOnMount.value = e === 'minutes';
    };
    const handleFocusTimeInput = () => {
      hasFocus.value = true;
    };
    const handleBlurTimeInput = () => {
      hasFocus.value = false;
      if (!hasError.value && props.value !== valueRef.value) {
        context.emit('input', valueRef.value);
      }
    };

    watch(
      () => props.value,
      (value) => {
        if (!hasFocus.value) {
          valueRef.value = value;
        }
      },
    );

    return {
      valueRef,
      hasError,
      hasFocus,
      focusHoursOnMount,
      focusMinutesOnMount,
      handleFocusTimeDisplay,
      handleFocusTimeInput,
      handleBlurTimeInput,
    };
  },
});
