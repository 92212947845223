




















import { defineComponent, type PropType } from '@vue/composition-api'
import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus'
import { getProcessName } from '../../utils/getProcessName'

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<BackgroundProcessStatus['process_type'] | null>,
      default: null,
    },
    disallowed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const options = ([1, 6, 5, 2, 3, 4, 7] as const).map((v) => ({ value: v, label: getProcessName(v) }))

    return {
      options,
    }
  },
})
