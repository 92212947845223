import workplaceApi from 'src/apis/workplace'
import { convertWorkplaceExtensionResponse } from 'src/models/workplaceExtension'

const SET_WORKPLACE = 'SET_WORKPLACE'

// the definition of initial state is required.
const state = {
  id: '',
  name: '',
  user_role: '',
  use_budget_group: false,
  use_activity_group: false,
  use_staff_label: false,
  use_staff_agency: false,
  use_shift_time: false,
  use_appropriation_dt: false,
  use_head_count: false,
  use_multiple_activity_unit: false,
  use_activity_memo: false,
  use_create_and_update_info: false,
  use_activity_time_overlap_check: false,
  workplace_extension: null,
}

function dup(some) {
  return JSON.parse(JSON.stringify(some))
}

const getters = {
  isWorkplaceSet: state => !!state.id,
  id: state => state.id,
  name: state => state.name,
  codeReaderType: state => state.code_reader_type,
  tabletPatterns: state => state.tablet_patterns.slice(),
  isWmsCsv: state => state.is_wms_csv,
  useBudgetGroup: state => state.use_budget_group,
  useActivityGroup: state => state.use_activity_group,
  useStaffLabel: state => state.use_staff_label,
  useStaffAgency: state => state.use_staff_agency,
  useShiftTime: state => state.use_shift_time,
  useAppropriationDt: state => state.use_appropriation_dt,
  useHeadCount: state => state.use_head_count,
  useMultipleActivityUnit: state => state.use_multiple_activity_unit,
  useActivityMemo: state => state.use_activity_memo,
  useCreateAndUpdateInfo: state => state.use_create_and_update_info,
  useActivityTimeOverlapCheck: state => state.use_activity_time_overlap_check,
  workplaceExtension: state => state.workplace_extension,
}

let promiseOfShow = null
const actions = {
  setWorkplace({ commit, state, getters }, workplace) {
    commit(SET_WORKPLACE, { workplace })
  },
  fetchWorkplaceById({ commit, state, getters }, { workplaceId }) {
    if (getters.isWorkplaceSet) {
      return Promise.resolve(Object.assign({}, state))
    }
    if (!promiseOfShow) {
      promiseOfShow = workplaceApi.show(workplaceId)
    }
    return promiseOfShow
      .then(({ data }) => {
        commit(SET_WORKPLACE, { workplace: data })
        return dup(state)
      })
  },
}

const mutations = {
  [SET_WORKPLACE](state, { workplace }) {
    state.id = workplace.id
    state.name = workplace.name
    state.user_role = workplace.user_role
    state.use_budget_group = workplace.use_budget_group
    state.use_activity_group = workplace.use_activity_group
    state.use_staff_label = workplace.use_staff_label
    state.use_staff_agency = workplace.use_staff_agency
    state.use_shift_time = workplace.use_shift_time
    state.use_appropriation_dt = workplace.use_appropriation_dt
    state.use_head_count = workplace.use_head_count
    state.use_multiple_activity_unit = workplace.use_multiple_activity_unit
    state.use_activity_memo = workplace.use_activity_memo
    state.use_create_and_update_info = workplace.use_create_and_update_info
    state.use_activity_time_overlap_check = workplace.use_activity_time_overlap_check
    state.workplace_extension = workplace.workplace_extension ? convertWorkplaceExtensionResponse(workplace.workplace_extension) : null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
