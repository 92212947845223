

























































import { defineComponent, type PropType, SetupContext, ref, getCurrentInstance } from '@vue/composition-api';
import { type AttendanceListItem } from '../../types';
import { formatTimeInteger } from 'src/util/datetime';
import staffShiftApi from 'src/apis/staffShift';
import { StaffType, StaffTypeSelectOptions } from 'src/consts';
import { vvGetError } from 'src/util/vee_validate';

export default defineComponent({
  props: {
    attendanceListItem: {
      type: Object as PropType<AttendanceListItem>,
      required: true,
    },
    workplaceId: {
      type: Number,
      required: true,
    },
    budgetGroupId: {
      type: Number,
      required: true,
    },
    dt: {
      type: Date,
      required: true,
    },
  },
  setup(props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()!.proxy.$root;
    const memo = ref<string | null>(props.attendanceListItem.memo);

    const getStaffTypeName = (staffType: StaffType | null): string | null => {
      return (
        StaffTypeSelectOptions.find((staffTypeSelectOption) => staffTypeSelectOption.id === staffType)?.name ?? null
      );
    };

    const updateShiftMemo = async (item: AttendanceListItem): Promise<void> => {
      if (item.staff_id === null || memo.value === null || memo.value.length > 255) {
        return;
      }

      await staffShiftApi.createOrUpdateShiftMemo({
        dt: props.dt,
        workplace_id: props.workplaceId,
        budget_group_id: props.budgetGroupId,
        staff_id: item.staff_id,
        memo: memo.value,
      });
    };

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }

    return {
      formatTimeInteger,
      getStaffTypeName,
      updateShiftMemo,
      getError,
      memo,
    };
  },
});
