import { type Ref } from '@vue/composition-api'
import regularShiftApi from 'src/apis/staffShift'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type BudgetGroup } from 'src/models/budgetGroup'

type Params = {
  budgetGroup: Ref<BudgetGroup>
  dt: Ref<Date>
}

type UploadShiftCsv = {
  uploadShiftCsv: (file: File) => Promise<boolean>
}

export function useUploadShiftCsv({ budgetGroup, dt }: Params): UploadShiftCsv {
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const uploadShiftCsv = errorBoundary.wrap(
    async (csv: File) => {
      await regularShiftApi.importCsv({
        workplace_id: budgetGroup.value.workplace_id,
        budget_group_id: budgetGroup.value.id,
        dt: dt.value,
        csv,
      })
      notifySuccess('CSVから登録を開始しました')
    }
  )

  return {
    uploadShiftCsv,
  }
}
