
import { defineComponent, PropType } from '@vue/composition-api'
import { isExist } from 'src/util/isExist'
import moment from 'src/util/moment-ja'
import { StaffGenderSelectOptions, StaffTypeSelectOptions } from 'src/consts'

type Staff = {
  id: number
  familyName: string
  nextProgressId: number | null
  type: number
  isSelected: boolean
  isRookie: boolean
  isKeyPlayer: boolean
}

export const PRODUCTIVITY_TYPE = {
  CURRENT: 'current',
  PLANNED: 'planned',
} as const
export type ProductivityType = typeof PRODUCTIVITY_TYPE[keyof typeof PRODUCTIVITY_TYPE]

export type Headcount = {
  // 元々のスタッフ数
  original: number
  // 移動したスタッフ数
  moved: number
  // 「必要人時」: 表示や、対必要人時差分の計算などに利用
  required: number | null
  // 「投下予定人時」: 表示や、対必要人時差分の計算などに利用
  planned: number
  // 「投下済人時」: 表示のみに利用
  result: number | null
  // 元々の対必要人時差分: スタッフを移動した際に表示される
  originalDiffRequired: number | null
  // 現在の対必要人時差分: スタッフを移動することで変動する
  currentDiffRequired: number | null
  // 対必要人時差分がマイナスかどうか
  isMinusDiff: boolean
  // 次の 1 時間の予定工数
  next: string
  // 次の次の 1 時間の予定工数
  nextNext: string
}

export type Time = {
  // 進捗名横の現在時刻
  current: moment.Moment
  // 「終了予測時刻」: 表示のみに利用
  predicted: string
  // 「目標」の時刻: 表示や、差分時間の計算などに利用
  target: moment.Moment
  // 次の 1 時間
  next: string | null
  // 次の次の 1 時間
  nextNext: string | null
}

export type Amount = {
  // 予定の数量: 表示のみに利用
  planned: number | string
  // 現在の完了数量: 表示のみに利用
  current: number
  // 目標数量: 表示のみに利用
  target: number
  // 完了数量 / 目標数量 の割合: 表示のみに利用
  percentComplete: number
  // 「残数量」: 表示や、必要人時、予測の計算などに利用
  remaining: number
}

export type Productivity = {
  // 「当日生産性」: 表示や、必要人時、予測の計算などに利用
  current: number | null
  // 「標準生産性」: 表示や、必要人時、予測の計算などに利用
  planned: number
  // 選択された生産性の種類: 必要人時などの計算に「当日生産性」か「標準生産性」のどちらを利用するかの切り替えに利用
  selectedType: ProductivityType
}

export type Progress = {
  id: number
  name: string
  color: string
  // 人時関連情報
  headcount: Headcount
  // 時刻関連情報
  time: Time
  // 数量関連情報
  amount: Amount
  // 生産性関連情報
  productivity: Productivity
  // スタッフ一覧
  staffs: Staff[]
  isLoading: boolean
  isHighPriority: boolean
}

const TIME_FORMAT = 'HH:mm'

export default defineComponent({
  name: 'progress-card',
  props: {
    progress: {
      type: Object as PropType<Progress>,
      default: null,
    },
    onStaffSelected: {
      type: Function as PropType<(staff: Staff) => void>
    },
  },
  setup() {
    return {
      isExist,
      PRODUCTIVITY_TYPE,
      StaffTypeSelectOptions,
      StaffSexes: StaffGenderSelectOptions
    }
  },
  filters: {
    timeFilter,
    hourFilter: (time: moment.Moment) => {
      return (time && !Number.isNaN(time.unix())) ? time.format('H') : '-'
    },
    minuteFilter: (time: moment.Moment) => {
      return (time && !Number.isNaN(time.unix())) ? time.format('m') : '-'
    },
    signValueFilter: (value: number, isFormat: boolean) => {
      if (!isExist(value)) {
        return '-'
      }
      const val = Number(formatValue(value))
      if (isNaN(val)) {
        return '-'
      }
      return `${val > 0 ? '+' : val === 0 ? '±' : ''}${isFormat ? formatValue(val) : val}`
    },
    naturalNumberFilter: (value: number | null) => {
      if (!isExist(value) || value < 0 || isNaN(value)) {
        return '-'
      }
      return formatValue(value)
    },
    localeStringFilter: (value: number) => value.toLocaleString(),
  },
})

export function timeFilter(time: moment.Moment) {
  return (time && !Number.isNaN(time.unix())) ? time.format(TIME_FORMAT) : '-'
}

function formatValue(value: number) {
  const val = Math.round(value * 100) / 100
  const [natural, decimal] = val.toLocaleString().split('.')
  return [natural, decimal ?? '0'].join('.')
}

