import { ComputedRef, Ref, computed, ref, watch, getCurrentInstance } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { DailySimulationTimeBlock } from '../types';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import { timeStrToTimeInteger, secondsToTimeInteger, timeIntegerToSeconds } from 'src/util/datetime';
import { useDailySimulation } from './useDailySimulation';
import HeadcountUpdateNotification from '../components/HeadcountUpdateNotification.vue';

type ModalContext = {
  event: MouseEvent;
  timeBlock: DailySimulationTimeBlock;
  timetableName: string | null;
};

type InjectionValue = {
  targetElement: ComputedRef<EventTarget | null | undefined>;
  timeBlock: ComputedRef<DailySimulationTimeBlock | undefined>;
  headcountToUpdate: Ref<number>;
  showsHeadcountUpdatePopover: ModalWithContext<ModalContext>['showsModal'];
  showHeadcountUpdatePopover: ModalWithContext<ModalContext>['showModal'];
  hideHeadcountUpdatePopover: ModalWithContext<ModalContext>['hideModal'];
  notifyHeadcountUpdate: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useHeadcountUpdatePopover');

function useHeadcountUpdateNotification(
  context: Ref<ModalContext | null>,
  headcountToUpdate: Ref<number>,
): { notifyHeadcountUpdate: () => void } {
  const root = getCurrentInstance()?.proxy!;
  const { state } = useDailySimulation();
  const originalTimeBlock = ref<DailySimulationTimeBlock | null>(null);
  const notifyHeadcountUpdate = () => {
    if (context.value === null || originalTimeBlock.value === null) {
      return;
    }

    const startTime = timeStrToTimeInteger(originalTimeBlock.value.displayTime);
    const endTime = secondsToTimeInteger(timeIntegerToSeconds(startTime) + 60 * state.unitMinutes);
    const props = {
      timetableName: context.value.timetableName,
      headcountFrom: originalTimeBlock.value.headcount,
      headcountTo: headcountToUpdate.value,
      startTime,
      endTime,
    };
    root.$notify({
      component: { render: (h) => h(HeadcountUpdateNotification, { props }) },
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      timeout: 10 * 1000,
    });
  };

  watch(context, (context) => {
    originalTimeBlock.value = context !== null ? { ...context.timeBlock } : null;
  });

  return {
    notifyHeadcountUpdate,
  };
}

export function useHeadcountUpdatePopoverProvider(): void {
  const {
    context,
    showsModal: showsHeadcountUpdatePopover,
    showModal: showHeadcountUpdatePopover,
    hideModal: hideHeadcountUpdatePopover,
  } = useModalWithContext<ModalContext>();
  const headcountToUpdate = ref(0);

  const { notifyHeadcountUpdate } = useHeadcountUpdateNotification(context, headcountToUpdate);

  watch(
    () => context.value,
    (newContext) => {
      headcountToUpdate.value = newContext?.timeBlock.headcount ?? 0;
    },
  );

  provide({
    targetElement: computed(() => context.value?.event.target),
    timeBlock: computed(() => context.value?.timeBlock),
    headcountToUpdate,
    showsHeadcountUpdatePopover,
    showHeadcountUpdatePopover,
    hideHeadcountUpdatePopover,
    notifyHeadcountUpdate,
  });
}

export function useHeadcountUpdatePopover(): InjectionValue {
  return inject();
}
