
import {
  defineComponent,
  SetupContext,
  computed,
  reactive,
  onMounted,
  ref,
  getCurrentInstance,
} from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import staffAttendanceApi from 'src/apis/staffAttendance';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import currentStaffWorkApi from 'src/apis/currentStaffWork';
import { ActivityStatusPanel } from 'src/views/Dashboard/Workplace/components/ActivityStatusPanel';
import { AttendanceStatusPanel } from 'src/views/Dashboard/Workplace/components/AttendanceStatusPanel';
import { AttendanceListRow } from '../AttendanceList/components/AttendanceListRow';
import { type AttendanceListItem } from './types';
import { format } from 'date-fns';
import { convertCurrentStaffWorkSumResponse, type CurrentStaffWork } from 'src/models/currentStaffWork';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { BudgetGroup } from 'src/models/budgetGroup';
import { StaffLabel } from 'src/models/staffLabel';
import { Staff } from 'src/models/staff';
import { StaffShift } from 'src/models/staffShift';
import { StaffAttendance } from 'src/models/staffAttendance';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import { SortSpec, useSorter2Container } from 'src/components/Workplace/Sorter2Container.vue';
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue';
import {
  ATTENDANCE_TYPE,
  ATTENDANCE_TYPES_AT_WORK,
  AttendanceType,
  StaffType,
  StaffTypeSelectOptions,
} from 'src/consts';
import { ATTENDANCE_STATUS_OPTIONS, ATTENDANCE_STATUS, AttendanceStatus } from './const';
import { formatTimeInteger } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { notifyError1 } from 'src/hooks/notificationHook';
import { MemoPanel } from './components/MemoPanel';
import { useMemoProvider, useMemo } from './composables/useMemo';

const displayPageName = '出勤者 一覧';

interface Pagination {
  perPage: number;
  currentPage: number;
  total: number;
}

interface SearchParams {
  macroOperationMasterId: number | null;
  attendanceStatus: number | null;
  staffLabelId: number | null;
  staffType: StaffType | null;
}

interface State {
  budgetGroups: BudgetGroup[];
  macroOperationMasters: MacroOperationMaster[];
  staffLabels: StaffLabel[];
  staffShifts: StaffShift[];
  staffs: Staff[];
  selectedMacroOperationMasters: MacroOperationMaster[];
  isExistStaff: boolean;
  currentStaffWork: CurrentStaffWork | null;
  attendanceListItems: AttendanceListItem[];
  isLoading: boolean;
}

function setupState(): State {
  const state: State = reactive({
    budgetGroups: [],
    macroOperationMasters: [],
    staffLabels: [],
    staffShifts: [],
    staffs: [],
    selectedMacroOperationMasters: [],
    isExistStaff: false,
    currentStaffWork: null,
    attendanceListItems: [],
    isLoading: false,
  });
  return state;
}

type ViewSettings = {
  start_time_font_color: string | null;
  end_time_font_color: string | null;
  icon_name: string | null;
  icon_color: string | null;
};
type TimeRange = {
  start_time: number | null;
  end_time: number | null;
};
export default defineComponent({
  components: {
    ActivityStatusPanel,
    AttendanceStatusPanel,
    Sorter2,
    Sorter2Container: useSorter2Container<AttendanceListItem>(),
    PaginationContainer,
    AttendanceListRow,
    MemoPanel,
  },
  setup(_props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()!.proxy.$root;
    const state = setupState();
    setPageName(vueInstance, displayPageName);
    const workplaceId = computed(() => {
      return Number(vueInstance.$route.params.workplaceId) ?? null;
    });
    const budgetGroupId = ref<number | null>(null);
    const dt = ref<Date>(new Date());

    const searchParams: SearchParams = reactive({
      macroOperationMasterId: null,
      attendanceStatus: null,
      staffType: null,
      staffLabelId: null,
    });

    useMemoProvider({
      workplaceId: workplaceId.value,
      dt: dt,
      budgetGroupId: budgetGroupId,
    });

    const { loadMemo } = useMemo();

    const pagination = reactive<Pagination>({
      perPage: 50,
      currentPage: 1,
      total: 0,
    });

    const defaultSortSpec = ref<SortSpec[]>([]);
    function resetToDefaultSortOrder(): void {
      defaultSortSpec.value = [
        { key: 'sortMacroOperationName', asc: true },
        { key: 'start_time', asc: true },
        { key: 'sortFullNameKana', asc: true },
      ];
    }
    resetToDefaultSortOrder();

    function getStaffExtensionApiParams() {
      return {
        staff_number: null,
        staff_id: null,
        budget_group_id: budgetGroupId.value,
        staff_agency_id: null,
        staff_label_id: searchParams.staffLabelId,
        is_enabled: null,
        staff_type: searchParams.staffType,
        macro_operation_master_id: searchParams.macroOperationMasterId,
      };
    }

    const onDisplayButtonClick = () => {
      setCurrentStaffWork();
      loadMemo();
    };

    async function initializeSearchPanel() {
      dt.value = new Date();
      const [{ data: budgetGroups }, macroOperationMasters, { data: staffLabels }] = await Promise.all([
        budgetGroupApi.index({
          workplaceId: workplaceId.value,
          params: { is_enabled: true },
        }),
        macroOperationMasterApi.getSubWorkplaceList({
          workplaceId: workplaceId.value,
          params: { is_enabled: true },
        }),
        staffLabelApi.index({
          workplaceId: workplaceId.value,
          params: { is_enabled: true },
        }),
      ]);
      budgetGroupId.value = budgetGroups[0].id;
      state.macroOperationMasters = macroOperationMasters;
      selectMacroOperationMasters();
      state.budgetGroups = budgetGroups;
      state.staffLabels = staffLabels;
    }

    async function setCurrentStaffWork() {
      state.isLoading = true;

      try {
        await loadStaffs();
        const { data } = await currentStaffWorkApi.getSum({
          workplace_id: workplaceId.value,
          dt: format(dt.value, 'yyyy-MM-dd'),
          budget_group_id: budgetGroupId.value,
        });
        state.currentStaffWork = convertCurrentStaffWorkSumResponse(data);
      } catch (err: any) {
        notifyError1(vueInstance, '出勤者の取得に失敗しました', { err });
      }

      state.isLoading = false;
    }

    // 管理グループを変更した際に検索パラメータを初期化
    async function initializeSearchStaffsParams() {
      searchParams.macroOperationMasterId = null;
      selectMacroOperationMasters();
    }

    async function loadStaffs({ resetsCurrentPage = true }: { resetsCurrentPage?: boolean } = {}) {
      if (!budgetGroupId.value) {
        return;
      }

      const [staffShifts, staffs, staffAttendances]: [StaffShift[], Staff[], StaffAttendance[]] = await Promise.all([
        staffShiftApi.sub_budget_group_list({
          dt: dt.value,
          workplace_id: workplaceId.value,
          budget_group_id: budgetGroupId.value,
          is_appropriation_target: true,
        }),
        staffExtensionApi.staffs({
          workplaceId: workplaceId.value,
          params: getStaffExtensionApiParams(),
        }),
        staffAttendanceApi.index({
          dt: dt.value,
          workplace_id: workplaceId.value,
          budget_group_id: budgetGroupId.value,
          is_appropriation_target: true,
        }),
      ]);
      const filteredStaffShifts = staffShifts.filter((staffShift) => {
        return (ATTENDANCE_TYPES_AT_WORK as AttendanceType[]).includes(staffShift.scheduled_attendance_type);
      });

      const staffIds = Array.from(
        new Set(filteredStaffShifts.map((v) => v.staff_id).concat(staffAttendances.map((v) => v.staff.id))),
      );

      const attendanceListItems: AttendanceListItem[] = staffIds.map((staff_id) => {
        const staffShift = filteredStaffShifts.find((staffShift) => staffShift.staff_id === staff_id);
        const staffAttendance = staffAttendances.find((staffAttendance) => staffAttendance.staff.id === staff_id);
        const staff = staffs.find((staff) => staff.id === staff_id);
        const attendanceStatus = getAttendanceStatus(staffAttendance, staffShift);
        const viewSetting = getViewSettings(attendanceStatus);
        const timeRange = getTimeRange(staffAttendance, staffShift, attendanceStatus);
        const memo = staffShifts.find((staffShift) => staffShift.staff_id === staff_id)?.memo ?? null;

        return {
          family_name: staff?.family_name ?? null,
          first_name: staff?.first_name ?? null,
          // 半角スペースソートのため鸞を入れる cf). src/assets/src/hooks/staffSortHook.ts
          sortFullNameKana: `${staff?.family_name_kana || '鸞'} ${staff?.first_name_kana || '鸞'}`,
          macro_operation_master_name: staff?.macro_operation_master ? staff.macro_operation_master.name : null,
          macro_operation_master_disp_color: staff?.macro_operation_master?.disp_color ?? 'FFFFFF',
          macro_operation_master_id: staff?.macro_operation_master?.id ?? null,
          // null/空文字の場合ソートが効かないため'-'で対応
          sortMacroOperationName: staff?.macro_operation_master ? staff.macro_operation_master.name : '-',
          start_time: timeRange.start_time,
          end_time: timeRange.end_time,
          staff_type: staff?.staff_extension?.staff_type ?? null,
          staff_label_name: staff?.staff_label?.label_name ?? null,
          staff_label_id: staff?.staff_label?.id ?? null,
          start_time_font_color: viewSetting.start_time_font_color,
          end_time_font_color: viewSetting.end_time_font_color,
          icon_name: viewSetting.icon_name,
          icon_color: viewSetting.icon_color,
          attendance_status: attendanceStatus,
          memo: memo,
          dt: dt.value,
          staff_id: staff?.id ?? null,
        };
      });

      state.attendanceListItems = attendanceListItems.filter((attendanceList): boolean => {
        const isAttendanceStatusMatch =
          searchParams.attendanceStatus === null || attendanceList.attendance_status === searchParams.attendanceStatus;
        const isStaffTypeMatch =
          searchParams.staffType === null || attendanceList.staff_type === searchParams.staffType;
        const isMacroOperationMasterMatch =
          searchParams.macroOperationMasterId === null ||
          attendanceList.macro_operation_master_id === searchParams.macroOperationMasterId;
        const isStaffLabelMatch =
          searchParams.staffLabelId === null || attendanceList.staff_label_id === searchParams.staffLabelId;

        return isAttendanceStatusMatch && isStaffTypeMatch && isMacroOperationMasterMatch && isStaffLabelMatch;
      });

      pagination.total = state.attendanceListItems.length;
      if (resetsCurrentPage) {
        pagination.currentPage = 1;
      }
    }

    const getAttendanceStatus = (staffAttendance?: StaffAttendance, staffShift?: StaffShift): AttendanceStatus => {
      if (staffAttendance !== undefined) {
        return staffAttendance.adjusted_work_end_time ? ATTENDANCE_STATUS.FINISHED : ATTENDANCE_STATUS.ATTENDING;
      } else if (staffShift !== undefined) {
        return staffShift.actual_attendance_type === ATTENDANCE_TYPE.ABSENCE
          ? ATTENDANCE_STATUS.ABSENCE
          : ATTENDANCE_STATUS.NOT_ATTENDING;
      }
      return ATTENDANCE_STATUS.NOT_ATTENDING;
    };

    const getViewSettings = (attendanceStatus: number): ViewSettings => {
      const LOGIMETER_BLUE = '#8497b0';
      const BLACK = 'black';
      const GRAY = '#bfbfbf';
      switch (attendanceStatus) {
        case ATTENDANCE_STATUS.ATTENDING:
          return {
            start_time_font_color: BLACK,
            end_time_font_color: LOGIMETER_BLUE,
            icon_name: null,
            icon_color: null,
          };
        case ATTENDANCE_STATUS.NOT_ATTENDING:
          return {
            start_time_font_color: LOGIMETER_BLUE,
            end_time_font_color: LOGIMETER_BLUE,
            icon_name: ATTENDANCE_STATUS_OPTIONS[ATTENDANCE_STATUS.NOT_ATTENDING].name,
            icon_color: LOGIMETER_BLUE,
          };
        case ATTENDANCE_STATUS.FINISHED:
          return {
            start_time_font_color: BLACK,
            end_time_font_color: BLACK,
            icon_name: ATTENDANCE_STATUS_OPTIONS[ATTENDANCE_STATUS.FINISHED].name,
            icon_color: GRAY,
          };
        default:
          return {
            start_time_font_color: null,
            end_time_font_color: null,
            icon_name: ATTENDANCE_STATUS_OPTIONS[ATTENDANCE_STATUS.ABSENCE].name,
            icon_color: GRAY,
          };
      }
    };
    const getTimeRange = (
      staffAttendance: StaffAttendance | undefined,
      staffShift: StaffShift | undefined,
      attendanceStatus: number,
    ): TimeRange => {
      const scheduled_start_time = staffShift ? staffShift.scheduled_work_start_time : null;
      const scheduled_end_time = staffShift ? staffShift.scheduled_work_end_time : null;
      const actual_start_time = staffAttendance ? staffAttendance.adjusted_work_start_time : null;
      const actual_end_time = staffAttendance ? staffAttendance.adjusted_work_end_time : null;
      switch (attendanceStatus) {
        case ATTENDANCE_STATUS.ATTENDING:
          return {
            start_time: actual_start_time,
            end_time: scheduled_end_time,
          };
        case ATTENDANCE_STATUS.NOT_ATTENDING:
          return {
            start_time: scheduled_start_time,
            end_time: scheduled_end_time,
          };
        case ATTENDANCE_STATUS.FINISHED:
          return {
            start_time: actual_start_time,
            end_time: actual_end_time,
          };
        default:
          return {
            start_time: null,
            end_time: null,
          };
      }
    };

    onMounted(async () => {
      await initializeSearchPanel();
    });

    const selectMacroOperationMasters = () => {
      state.selectedMacroOperationMasters = isExist(budgetGroupId.value)
        ? state.macroOperationMasters.filter(
            (macroOperationMaster) => macroOperationMaster.budget_group_id === budgetGroupId.value,
          )
        : state.macroOperationMasters;
    };

    return {
      state,
      searchParams,
      pagination,
      defaultSortSpec,
      StaffTypeSelectOptions,
      formatTimeInteger,
      initializeSearchStaffsParams,
      onDisplayButtonClick,
      ATTENDANCE_STATUS_OPTIONS,
      workplaceId,
      dt,
      budgetGroupId,
    };
  },
});
