import { TimetableMasterResponse } from 'src/models/api/timetableMasterResponse';
import { convertPlanProgressBlockMasterResponse, PlanProgressBlockMaster } from 'src/models/planProgressBlockMaster';
import {
  convertTimetableMasterLogimeterActivityResponse,
  TimetableMasterLogimeterActivity,
} from 'src/models/timetableMasterLogimeterActivity';
import { ProgressHeader } from './progressHeader';
import { TimetableLabel } from 'src/models/timetableLabel';

export interface TimetableMaster {
  id: number;
  workplace_id: number;
  budget_group_id: number;
  timetable_label_id: number;
  name: string;
  use_in_prep_phase: boolean;
  use_in_do_phase: boolean;
  use_in_review_phase: boolean;
  planned_productivity: number;
  start_time: number;
  end_time: number;
  break1_start_time: number | null;
  break1_end_time: number | null;
  break2_start_time: number | null;
  break2_end_time: number | null;
  planned_quantity: number | null;
  first_half_target_ratio: number | null;
  max_allocations: number | null;
  display_start_time: number;
  target_time: number | null;
  man_hour_only: boolean;
  should_aggregate_transactions: boolean;
  is_prior: boolean;
  appropriation_dt_diff_start: number | null;
  appropriation_dt_diff_end: number | null;
  disp_order: number;
  disp_color: string;
  is_enabled: boolean;
  plan_progress_block_masters: PlanProgressBlockMaster[];
  timetable_master_logimeter_activities: TimetableMasterLogimeterActivity[];
  timetable_label?: TimetableLabel;
}

export type TimetableMasterWithProgressHeaders = TimetableMaster & {
  progress_headers: ProgressHeader[];
};

export function convertTimetableMasterResponse(response: TimetableMasterResponse): TimetableMaster {
  const { plan_progress_block_masters, timetable_master_logimeter_activities, ...rest } = response;
  return {
    ...rest,
    planned_productivity: Number(response.planned_productivity),
    plan_progress_block_masters:
      response.plan_progress_block_masters
        ?.map(convertPlanProgressBlockMasterResponse)
        .sort((a, b) => a.time_period - b.time_period) ?? [],
    timetable_master_logimeter_activities:
      response.timetable_master_logimeter_activities?.map(convertTimetableMasterLogimeterActivityResponse) ?? [],
  };
}
