import { getCurrentInstance, ref, type Ref } from '@vue/composition-api'
import regularShiftApi from 'src/apis/staffShift'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type StaffWithShifts } from 'src/models/regularShift'
import { createInjection } from 'src/util/createInjection'

type Params = {
  budgetGroup: Ref<BudgetGroup>
  dt: Ref<Date>
}

type InjectionValue = {
  staffWithShifts: Ref<StaffWithShifts[]>
  createShiftCsvTemplate: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useCreateShiftCsvTemplate')

export function useCreateShiftCsvTemplateProvider({ budgetGroup, dt }: Params): void {
  const root = getCurrentInstance()?.proxy!
  const errorBoundary = useErrorBoundary()
  const staffWithShifts = ref<StaffWithShifts[]>([])
  const createShiftCsvTemplate = errorBoundary.wrap(
    async () => {
      await regularShiftApi.createCsvTemplate({
        workplace_id: budgetGroup.value.workplace_id,
        budget_group_id: budgetGroup.value.id,
        dt: dt.value.toISOString(),
        staff_ids: staffWithShifts.value.filter((v) => v.is_enabled).map((v) => v.staff_id),
      })

      const { href } = root.$router.resolve({
        name: 'WorkplaceBackgroundProcessStatus',
        query: { search: '1' },
      })
      window.open(href, '_blank')
    }
  )

  provide({
    staffWithShifts,
    createShiftCsvTemplate,
  })
}

export function useCreateShiftCsvTemplate(): InjectionValue {
  return inject()
}
