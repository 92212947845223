
import Vue from 'vue'
import { defineComponent, SetupContext, computed, reactive, onMounted } from '@vue/composition-api'
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { BudgetGroup } from 'src/models/budgetGroup'
import { convertStaffResponse, Staff } from 'src/models/staff'
import { isExist } from 'src/util/isExist'
import moment from 'src/util/moment-ja'
import Sort from 'src/util/sort'
import Sorter from 'src/views/Dashboard/Workplace/Data/Sorter.vue'
import planProgressApi from 'src/apis/planProgress'
import { timeIntegerToMoment } from 'src/util/datetime'
import { mockSkills as skillsMaster } from './mock'
import ProgressCard, { Amount, Headcount, Productivity, PRODUCTIVITY_TYPE, Progress, Time, timeFilter } from './ProgressCard/index.vue'
import SimulationModal, { simulationModalHooks } from './SimulationModal/index.vue'
import { STAFF_GENDER, StaffGenderSelectOptions, StaffTypeSelectOptions } from 'src/consts'
import { createTimetableBudgets, getAmountData, getHeadCountData, getPredictedCompleteTime, getProductivity } from 'src/util/planProgressHelper'
import { PlanProgressBlock } from 'src/models/planProgressBlock'
import { convertTimetableHeaderResponse, TimetableHeaderWithStaffs } from 'src/models/timetableHeader';

type AssignmentSimulationStaff = {
  id: number
  familyName: string
  fullName: string
  fullNameKana: string
  currentProgressId: number
  currentProgressName: string
  nextProgressId: number | null
  type: number
  gender: number
  isSelected: boolean
  isRookie: boolean
  isKeyPlayer: boolean
  isAlreadyMoved: boolean
  startingTime: moment.Moment
  closingTime: moment.Moment
  breakTime: moment.Moment
  skills: Array<{ id: number, name: string }>
}

export type ProgressEx = Progress & {
  currentHeadCount: number
  isUseCurrentProductivity: boolean
  plannedMoveHeadcount: number | null
  originalDiffRequiredHeadcountByCurrentProductivity: number | null
}

type State = {
  pageName: string | null
  targetDate: Date | null
  workplaceId: string
  budgetGroupId: number | null
  budgetGroups: BudgetGroup[]
  progressMap: Record<number, ProgressEx>
  progresses: ProgressEx[]
  filteredProgresses: ProgressEx[]
  staffs: AssignmentSimulationStaff[]
  selectedStaffs: AssignmentSimulationStaff[]
  sortableList: Sort
  isShowAllStaffs: boolean
  isOnlyHighPriority: boolean
  isFetching: boolean
  goodProgresses: ProgressEx[]
  badProgresses: ProgressEx[]
}

function setupState(context: SetupContext): State {
  const root = context.root as Vue
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    targetDate: moment().toDate(),
    budgetGroupId: null,
    budgetGroups: [],
    progressMap: {} as Record<number, ProgressEx>,
    progresses: computed(() => Object.values(state.progressMap)),
    filteredProgresses: computed(() => state.progresses.filter(progress => !state.isOnlyHighPriority || progress.isHighPriority)),
    staffs: [],
    selectedStaffs: [],
    workplaceId: computed(() => {
      return root.$route.params.workplaceId
    }),
    sortableList: {} as any,
    isShowAllStaffs: false,
    isOnlyHighPriority: false,
    isFetching: false,
    goodProgresses: computed(() => state.filteredProgresses
      .filter(progress => {
        if (isExist(progress.plannedMoveHeadcount)) {
          return progress.plannedMoveHeadcount < 0
        }
        return isExist(progress.headcount.originalDiffRequired) && progress.headcount.originalDiffRequired >= 0
      })
      .sort((a, b) => b.headcount.originalDiffRequired! - a.headcount.originalDiffRequired!)),
    badProgresses: computed(() => state.filteredProgresses
      .filter(progress => {
        if (isExist(progress.plannedMoveHeadcount)) {
          return progress.plannedMoveHeadcount > 0
        }
        return !isExist(progress.headcount.originalDiffRequired) || isNaN(progress.headcount.originalDiffRequired) || progress.headcount.originalDiffRequired < 0
      })
      .sort((a, b) => (a.headcount.originalDiffRequired ?? 0) - (b.headcount.originalDiffRequired ?? 0))),
  })
  state.sortableList = new Sort(state, 'selectedStaffs', null)
  return state
}

const AssignmentSimulation = defineComponent({
  components: {
    ProgressCard,
    SimulationModal,
    Sorter,
  },

  setup(props, context: SetupContext) {
    const root = context.root as Vue
    setPageName(root, '配置変更')
    const state = setupState(context)
    const selectedStaffListRowStyle = { gridTemplateColumns: `34px 130px 150px 150px 60px 60px 60px 60px 80px 80px 80px ${Array(skillsMaster.length).fill('72px').join(' ')}` }
    // const selectedStaffListRowStyle = { gridTemplateColumns: `34px 130px 150px 150px 60px 60px 60px 60px` }
    const referenceMoment = moment()
    const { state: simulationModalState, openSimulationModal } = simulationModalHooks()

    onMounted(async() => {
      state.budgetGroups = await budgetGroupApi.index(state.workplaceId)
    })

    async function resetData() {
      clear()
      const timetableHeaders = await fetchData()

      state.staffs = []
      state.progressMap = {}

      if (!isExist(timetableHeaders)) {
        return
      }

      const progressMap: Record<number, ProgressEx> = {}
      timetableHeaders.forEach(item => {
        progressMap[item.id] = createProgress(item, referenceMoment)
      })
      state.progressMap = progressMap

      // ソート状態をリセット
      state.sortableList.sortBy([])
    }

    async function fetchData(): Promise<TimetableHeaderWithStaffs[]> {
      // 日付は必須
      if (!state.targetDate) {
        return []
      }

      state.isFetching = true
      const { data } = await planProgressApi.indexWithStaff(state.workplaceId, {
        dt: moment(state.targetDate).format('YYYY-MM-DD'),
        budget_group_ids: isExist(state.budgetGroupId) ? [state.budgetGroupId] : null
      })
      state.isFetching = false
      return data.map(e => ({
        ...convertTimetableHeaderResponse(e),
        staffs: e.staffs.map(convertStaffResponse),
      }))
    }

    function createStaff(staff: Staff, referenceMoment: moment.Moment, progressId: number): AssignmentSimulationStaff {
      const staffState: AssignmentSimulationStaff = reactive({
        id: staff.id,
        familyName: staff.family_name,
        fullName: `${staff.family_name || ''} ${staff.first_name || ''}`,
        fullNameKana: `${staff.family_name_kana || ''} ${staff.first_name_kana || ''}`,
        currentProgressId: progressId,
        currentProgressName: computed(() => findProgress(staffState.currentProgressId)?.name ?? ''),
        nextProgressId: null,
        type: staff.staff_extension?.staff_type ?? 0,
        gender: staff.staff_extension?.gender ?? STAFF_GENDER.NO_ANSWER,
        isSelected: false,
        isRookie: !!staff.staff_extension?.is_beginner,
        isKeyPlayer: !!staff.staff_extension?.is_key_player,
        isAlreadyMoved: false,
        startingTime: timeIntegerToMoment(referenceMoment, staff.regular_start_time),
        closingTime: timeIntegerToMoment(referenceMoment, staff.regular_end_time),
        breakTime: timeIntegerToMoment(referenceMoment, staff.break_time),
        skills: [],
      })
      return staffState
    }

    function findProgress(id: number | null): ProgressEx | undefined | null {
      return !isExist(id) ? null : state.progressMap[id]
    }

    function createProgress(data: TimetableHeaderWithStaffs, referenceMoment: moment.Moment): ProgressEx {
      state.staffs = state.staffs.concat((data.staffs ?? []).map(staff => createStaff(staff, referenceMoment, data.id)))
      const allBudgets = data.plan_progress_blocks.concat().sort((a, b) => a.hour - b.hour)
      const currentBudgetIndex = allBudgets.reduce((index, budget, i) => isExist(budget.result_amount) ? i : index, -1)
      const { currentResultAmount, currentScheduledAmount, lastScheduledAmount } = getAmountData(allBudgets, currentBudgetIndex)
      const { sumResultHeadCount, sumScheduledHeadCountFromCurrent } = getHeadCountData(allBudgets, currentBudgetIndex)
      const currentBudget = allBudgets[currentBudgetIndex]
      const nextBudget = allBudgets[currentBudgetIndex + 1]
      const nextNextBudget = allBudgets[currentBudgetIndex + 2]
      const currentTime = timeIntegerToMoment(referenceMoment, currentBudget ? Number(`${currentBudget.hour + 1}0000`) : 0)

      const amountWorkBeforehand = data.amount_work_beforehand ?? 0
      const headcountWorkBeforehand = isExist(data.headcount_work_beforehand) ? Number(data.headcount_work_beforehand) : 0
      const targetTime = isExist(data.target_time) ? timeIntegerToMoment(referenceMoment, data.target_time) : moment(null)
      const currentProductivity = getProductivity(currentResultAmount, sumResultHeadCount, amountWorkBeforehand)
      const plannedProductivity = Number(data.timetable_master.planned_productivity ?? 0)
      const remainingAmount = (!isExist(lastScheduledAmount) || !isExist(currentResultAmount)) ? 0 : Math.max(lastScheduledAmount - currentResultAmount, 0)
      const remainingHour = targetTime.diff(currentTime, 'minute', true) / 60
      const headcount: Headcount = reactive({
        originalDiffRequired: computed(() => {
          if (!isExist(headcount.required)) { return null }
          const remainingHour = time.target.diff(time.current, 'hour')
          if (remainingHour < 0) { return null }

          return (headcount.planned - headcount.required) / remainingHour
        }),
        currentDiffRequired: computed(() => !isExist(headcount.required) ? null : headcount.planned - headcount.required),
        isMinusDiff: computed(() => !isExist(headcount.originalDiffRequired) ? false : headcount.originalDiffRequired < 0),
        required: computed(() => !isExist(currentProductivity) ? null : Math.max(remainingAmount / (progress.isUseCurrentProductivity ? currentProductivity : plannedProductivity), 0)),
        planned: sumScheduledHeadCountFromCurrent ?? 0,
        result: (sumResultHeadCount ?? 0) + headcountWorkBeforehand,
        original: state.staffs.filter(staff => staff.currentProgressId === data.id).length,
        moved: computed(() => progress.staffs.reduce((count, staff) => count + (!staff.isSelected ? 0 : staff.nextProgressId === progress.id ? 1 : -1), 0)),
        next: nextBudget?.scheduled_headcount?.toFixed(1).split('.')[0] ?? '-',
        nextNext: nextNextBudget?.scheduled_headcount?.toFixed(1).split('.')[0] ?? '-',
      })
      const time: Time = reactive({
        current: currentTime,
        predicted: computed(() => getPredictedTime(progress.isUseCurrentProductivity ? currentProductivity : plannedProductivity, allBudgets, amountWorkBeforehand, lastScheduledAmount)),
        target: targetTime,
        next: nextBudget ? `${`0${nextBudget.hour}`.slice(-2)}:00~` : null,
        nextNext: nextNextBudget ? `${`0${nextNextBudget.hour}`.slice(-2)}:00~` : null,
      })
      const amount: Amount = reactive({
        planned: currentScheduledAmount ?? '-',
        current: currentResultAmount ?? 0,
        target: lastScheduledAmount ?? 0,
        percentComplete: (isExist(currentResultAmount) && isExist(lastScheduledAmount)) ? Math.round(currentResultAmount / lastScheduledAmount * 100) : 0,
        remaining: remainingAmount,
      })
      const productivity: Productivity = reactive({
        current: currentProductivity,
        planned: plannedProductivity,
        selectedType: isExist(currentProductivity) ? PRODUCTIVITY_TYPE.CURRENT : PRODUCTIVITY_TYPE.PLANNED,
      })
      const progress: ProgressEx = reactive({
        id: data.id,
        name: data.timetable_master.name,
        color: data.timetable_master.disp_color,
        headcount,
        time,
        amount,
        productivity,
        staffs: computed(() => [...state.staffs.filter(staff => staff.nextProgressId === progress.id), ...state.staffs.filter(staff => staff.currentProgressId === progress.id)]),
        isLoading: false,
        isHighPriority: !!data.timetable_master.is_prior,
        currentHeadCount: computed(() => headcount.original + headcount.moved),
        isUseCurrentProductivity: computed(() => productivity.selectedType === PRODUCTIVITY_TYPE.CURRENT),
        plannedMoveHeadcount: getPlannedMoveHeadcount(headcount.original, nextBudget, nextNextBudget),
        originalDiffRequiredHeadcountByCurrentProductivity: computed(() => !isExist(currentProductivity) ? null : headcount.original * remainingHour - Math.max(remainingAmount / currentProductivity, 0)),
      })
      return progress
    }

    function getPlannedMoveHeadcount(currentHeadcount: number, nextBudget: PlanProgressBlock | null, nextNextBudget: PlanProgressBlock | null): number | null {
      const nextHeadcount = Number(nextBudget?.scheduled_headcount) || 0

      let movedHeadcount: number = nextHeadcount - currentHeadcount
      if (movedHeadcount !== 0) {
        return movedHeadcount
      }

      const nextNextHeadcount = Number(nextNextBudget?.scheduled_headcount) || 0
      movedHeadcount = nextNextHeadcount - currentHeadcount
      if (movedHeadcount !== 0) {
        return movedHeadcount
      }

      return null
    }

    function getPredictedTime(productivity: number | null, budgets: PlanProgressBlock[], amountWorkBeforehand: number, lastScheduledAmount: number | null) {
      const timetableBudgets = createTimetableBudgets(budgets, amountWorkBeforehand)
      return getPredictedCompleteTime(timetableBudgets, productivity, lastScheduledAmount)
    }

    function clear() {
      state.selectedStaffs.forEach(staff => {
        staff.isSelected = false
        staff.isAlreadyMoved = false
        staff.nextProgressId = null
      })
      state.selectedStaffs.length = 0
      state.sortableList.sortBy([])
    }

    function toggleIsShowAllStaffs() {
      state.isShowAllStaffs = !state.isShowAllStaffs
    }

    function selectStaff(staff: AssignmentSimulationStaff) {
      if (staff.isSelected) { return }
      staff.isSelected = true
      state.selectedStaffs.unshift(staff)
      state.sortableList.sortBy([])
      beginLoadingAnimation(staff.currentProgressId)
    }

    function deselectStaff(staff: AssignmentSimulationStaff) {
      if (!staff.isSelected) { return }
      staff.isSelected = false
      staff.isAlreadyMoved = false
      staff.nextProgressId = null
      state.selectedStaffs = state.selectedStaffs.filter(staff => staff.isSelected)
    }

    function onNextProgressChanged(staff: AssignmentSimulationStaff) {
      if (staff.isAlreadyMoved) {
        return
      }
      state.selectedStaffs = state.selectedStaffs.filter(_staff => _staff.id !== staff.id)
      state.selectedStaffs.push(staff)
      staff.isAlreadyMoved = true
      beginLoadingAnimation(staff.nextProgressId)
    }

    function beginLoadingAnimation(progressId: number | null) {
      const progress = findProgress(progressId)
      if (isExist(progress)) {
        progress.isLoading = true
        setTimeout(() => {
          progress.isLoading = false
        }, 200)
      }
    }

    function sortBySkill({ state, target, sortObjects }: Sort, sortKey: string) {
      const sortObject = sortObjects?.find(sortObject => sortObject.key === sortKey)
      if (!isExist(sortObject)) {
        return
      }

      const { asc: _asc, key } = sortObject
      const asc = (_asc ? -1 : 1)
      const items: AssignmentSimulationStaff[] = state[target]
      items.sort((_a, _b) => {
        const a = _a.skills.find(skill => skill.id === Number(key))
        const b = _b.skills.find(skill => skill.id === Number(key))
        if (!isExist(a) && !isExist(b)) {
          return 0
        }
        if (!isExist(a)) {
          return asc
        }
        if (!isExist(b)) {
          return -asc
        }
        if (a === b) {
          return 0
        }
        return a > b ? asc : -asc
      })
    }

    function onOpenSimulationModalButtonClick() {
      openSimulationModal([...state.badProgresses, ...state.goodProgresses], state.targetDate)
    }

    return {
      state,
      clear,
      toggleIsShowAllStaffs,
      resetData,
      selectStaff,
      deselectStaff,
      onNextProgressChanged,
      skillsMaster,
      sortBySkill,
      selectedStaffListRowStyle,
      isExist,
      simulationModalState,
      onOpenSimulationModalButtonClick,
      StaffGenderSelectOptions,
      StaffTypeSelectOptions
    }
  },

  filters: {
    timeFilter,
  },
})
export default AssignmentSimulation

