import { isTimeRange } from './isTimeRange';
import { isValidTimeRange } from './isValidTimeRange';
import { isValid15StepMinutes } from '../TimeInteger/isValid15StepMinutes';

export function isValid15StepTimeRange(timeRange: unknown): boolean {
  if (!isTimeRange(timeRange)) {
    return false;
  }

  if (!isValidTimeRange(timeRange)) {
    return false;
  }

  return isValid15StepMinutes(timeRange.startTime) && isValid15StepMinutes(timeRange.endTime);
}
