




























































import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useHeadcountUpdatePopover } from '../composables/useHeadcountUpdatePopover';
import { ValidationObserver } from 'vee-validate';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { isExist } from 'src/util/isExist';
import { useDailySimulationValidationRules } from '../composables/useDailySimulationValidationRules';
import { FormItem } from 'src/components/UIComponents/Form';
import NumberInput from 'src/components/UIComponents/Inputs/NumberInput/NumberInput.vue';

const Y_MARGIN = 16;
const LEFT_MARGIN = 50;

export default defineComponent({
  components: {
    FormItem,
    NumberInput,
    PrimaryButton,
    ValidationObserver,
  },
  setup(_, context) {
    const {
      targetElement,
      timeBlock,
      headcountToUpdate,
      hideHeadcountUpdatePopover,
      showsHeadcountUpdatePopover,
      notifyHeadcountUpdate,
    } = useHeadcountUpdatePopover();

    const { headcountValidationRule } = useDailySimulationValidationRules();

    const isTop = ref(false);
    const top = ref(0);
    const left = ref(0);
    const rootElement = ref<HTMLDivElement>();
    const popoverElement = ref<HTMLDivElement>();
    const lastTimerKey = ref(0);

    // popoverElementはv-ifの制御が切り替わると変化する
    // targetElementはshowsHeadcountUpdatePopoverによって変更される
    // つまり、Popoverをマウントした要素同士の間で、どの要素が選択されたか、またPopoverが表示されているかが変更されたタイミングで発火する
    watch(
      () => [targetElement.value, popoverElement.value],
      () => {
        window.clearInterval(lastTimerKey.value);
        if (!(targetElement.value instanceof HTMLDivElement) || !(popoverElement.value instanceof HTMLDivElement)) {
          return;
        }
        if (!showsHeadcountUpdatePopover) {
          return;
        }
        resetPopoverPosition();
        lastTimerKey.value = window.setInterval(resetPopoverPosition, 100);
      },
    );

    // Popoverの描画エリアが画面からはみ出さないように位置を調節
    // 画面の下にPopoverがはみ出す場合は、上に表示する(isTop = true)
    const resetPopoverPosition = () => {
      if (!(targetElement.value instanceof HTMLDivElement) || !(popoverElement.value instanceof HTMLDivElement)) {
        return;
      }
      const targetRect = targetElement.value.getBoundingClientRect();
      const popoverRect = popoverElement.value.getBoundingClientRect();
      const isOver = targetRect.top + targetRect.height + popoverRect.height > window.innerHeight;
      top.value = isOver
        ? targetRect.top - popoverRect.height - Y_MARGIN
        : targetRect.top + targetRect.height + Y_MARGIN;
      left.value = targetRect.left + (targetRect.width - popoverRect.width) / 2 - LEFT_MARGIN;
      isTop.value = isOver;
    };

    const isReadyToHeadcountUpdate = computed(() => {
      return headcountToUpdate.value !== timeBlock.value?.headcount;
    });

    const updateHeadcount = () => {
      if (!isExist(timeBlock.value)) {
        return;
      }
      notifyHeadcountUpdate();
      context.emit('update', timeBlock.value, headcountToUpdate.value);
      hideHeadcountUpdatePopover();
    };

    const onDocumentClick = (event: MouseEvent) => {
      const target = event.target;
      if (!showsHeadcountUpdatePopover.value) {
        return;
      }
      if (!(target instanceof HTMLElement)) {
        return;
      }

      // クリックイベントが発生した直接のHTML要素がPopover内の要素である場合は、Popoverを閉じない
      if (isExist(target.closest('.HeadcountUpdatePopover-popover'))) {
        return;
      }
      if (isExist(target.closest('.HeadcountTimeBlock-root'))) {
        return;
      }
      hideHeadcountUpdatePopover();
    };

    onMounted(() => {
      document.addEventListener('click', onDocumentClick);
    });

    onUnmounted(() => {
      document.removeEventListener('click', onDocumentClick);
    });

    return {
      top,
      left,
      rootElement,
      popoverElement,
      targetElement,
      timeBlock,
      headcountToUpdate,
      showsHeadcountUpdatePopover,
      headcountValidationRule,
      isTop,
      isReadyToHeadcountUpdate,
      hideHeadcountUpdatePopover,
      updateHeadcount,
      isExist,
    };
  },
});
