

























































import { defineComponent, reactive, watch } from '@vue/composition-api';
import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { TimeRangeInput } from 'src/components/UIComponents/Inputs/TimeRangeInput';
import { Validator, useCustomValidator } from 'src/composables/useCustomValidator';
import { useCommonSetting } from '../composables/useCommonSetting';
import { DailySimulationCommonSettingState } from '../types';
import {
  isStrictTimeRange,
  isNullTimeRange,
  isValid15StepTimeRange,
  overlapsWithTimeRange,
} from 'src/values/TimeRange';
import { isValid15StepMinutes } from 'src/values/TimeInteger/isValid15StepMinutes';

const targetClockOutTimeValidator: Validator = {
  name: 'targetClockOutTime',
  validate(value: unknown) {
    if (typeof value !== 'number') {
      return false;
    }
    return isValid15StepMinutes(value);
  },
};

const break1TimeRangeValidator: Validator = {
  name: 'break1TimeRange',
  validate(break1: unknown, targets) {
    const break2 = Array.isArray(targets) ? targets[0] : null;
    if (isStrictTimeRange(break2)) {
      if (!isStrictTimeRange(break1)) {
        return false;
      }
      if (overlapsWithTimeRange(break1, break2)) {
        return false;
      }
    }
    if (isNullTimeRange(break1)) {
      return true;
    }
    return isValid15StepTimeRange(break1);
  },
  options: { hasTarget: true },
};

const break2TimeRangeValidator: Validator = {
  name: 'break2TimeRange',
  validate(break2: unknown, targets) {
    const break1 = Array.isArray(targets) ? targets[0] : null;
    if (!isStrictTimeRange(break1) && isStrictTimeRange(break2)) {
      return false;
    }
    if (isNullTimeRange(break2)) {
      return true;
    }
    if (!isValid15StepTimeRange(break2)) {
      return false;
    }
    if (isStrictTimeRange(break1) && isStrictTimeRange(break2)) {
      if (overlapsWithTimeRange(break2, break1)) {
        return false;
      }
      if (break2.endTime <= break1.startTime) {
        return false;
      }
    }
    return true;
  },
  options: { hasTarget: true },
};

export default defineComponent({
  components: {
    FormGroup,
    FormItem,
    ModalForm,
    TimeInput,
    TimeRangeInput,
  },
  setup() {
    const targetClockOutTimeValidationRule = useCustomValidator(targetClockOutTimeValidator);
    const break1TimeRangeRule = useCustomValidator(break1TimeRangeValidator);
    const break2TimeRangeRule = useCustomValidator(break2TimeRangeValidator);
    const { commonSetting, hideCommonSettingModal, setCommonSetting, showsCommonSettingModal } = useCommonSetting();
    const state: DailySimulationCommonSettingState = reactive({
      targetClockOutTime: null,
      break1TimeRange: reactive({ startTime: null, endTime: null }),
      break2TimeRange: reactive({ startTime: null, endTime: null }),
    });

    watch(
      () => showsCommonSettingModal.value,
      () => {
        state.targetClockOutTime = commonSetting.targetClockOutTime;
        state.break1TimeRange = { ...commonSetting.break1TimeRange };
        state.break2TimeRange = { ...commonSetting.break2TimeRange };
      },
    );

    const onSubmit = () => {
      setCommonSetting(state);
      hideCommonSettingModal();
    };

    return {
      state,
      targetClockOutTimeValidationRule,
      break1TimeRangeRule,
      break2TimeRangeRule,
      showsCommonSettingModal,
      hideCommonSettingModal,
      onSubmit,
    };
  },
});
