
import Vue from 'vue';
import { defineComponent, SetupContext, onMounted, provide, inject } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';

import ShiftEditModal from 'src/components/Workplace/ShiftEditModal/index.vue';
import ConfirmModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ConfirmModal/index.vue';
import YearSummaryModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/YearSummaryModal/index.vue';
import BulkShiftCreationModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/BulkShiftCreationModal/index.vue';
import ReceptionShiftModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ReceptionShiftModal/index.vue';
import SettingDisplayItemModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/SettingDisplayItemModal/index.vue';
import DisplayConditionSettingModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/DisplayConditionSettingModal/index.vue';
import ShiftRegisterModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ShiftRegisterModal/index.vue';
import ShiftBulkEditModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ShiftBulkEditModal/index.vue';
import RegularShiftHeader from 'src/views/Dashboard/Workplace/RegularShift/components/RegularShiftHeader/index.vue';
import ShiftTotalTable from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTotalTable/index.vue';
import ShiftTable from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTable/index.vue';
import UpdateMonthlySummaryModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/UpdateMonthlySummaryModal/index.vue';
import ShiftCsvOutputModal from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ShiftCsvOutputModal/index.vue';

// カスタムhooks
import { ModalDisplayStateKey, useModal } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import {
  ConfirmModalDisplayStateKey,
  useConfirmModal,
} from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ConfirmModal/useConfirmModal';
import { PageDisplayStateKey, usePageDisplay } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay';
import { StaffWithShiftsStateKey, useShift } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import {
  SettingDisplayItemStateKey,
  useSettingDisplayItem,
} from 'src/views/Dashboard/Workplace/RegularShift/hooks/useSettingDisplayItem';
import { CommonStateKey, useCommonState } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import {
  DisplayConditionStateKey,
  useDisplayCondition,
} from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import { YearSummaryStateKey, useYearSummary } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useYearSummary';
import { ShiftRegisterStateKey, useShiftRegister } from './hooks/useShiftRegister';
import { ShiftBulkEditStateKey, useShiftBulkEdit } from './hooks/useShiftBulkEdit';
import { useCreateShiftCsvTemplateProvider } from './hooks/useCreateShiftCsvTemplate';
import { useLoadLogimeterStaffAttendancesProvider } from './hooks/useLoadLogimeterStaffAttendances';
import { ManHoursStateKey, useManHours } from './hooks/useManHours';
import { BudgetGroupPlanBoardMiscsStateKey, useBudgetGroupPlanBoardMiscs } from './hooks/useBudgetGroupPlanBoardMiscs';

export default defineComponent({
  components: {
    ShiftEditModal,
    YearSummaryModal,
    ConfirmModal,
    BulkShiftCreationModal,
    ReceptionShiftModal,
    SettingDisplayItemModal,
    DisplayConditionSettingModal,
    ShiftRegisterModal,
    ShiftBulkEditModal,
    RegularShiftHeader,
    ShiftTotalTable,
    ShiftTable,
    UpdateMonthlySummaryModal,
    ShiftCsvOutputModal,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'レギュラーシフト');

    provide(ModalDisplayStateKey, useModal());
    provide(ConfirmModalDisplayStateKey, useConfirmModal());
    provide(PageDisplayStateKey, usePageDisplay());
    provide(CommonStateKey, useCommonState());
    provide(StaffWithShiftsStateKey, useShift(root));
    provide(SettingDisplayItemStateKey, useSettingDisplayItem());
    provide(DisplayConditionStateKey, useDisplayCondition());
    provide(YearSummaryStateKey, useYearSummary());
    provide(ShiftRegisterStateKey, useShiftRegister());
    provide(ShiftBulkEditStateKey, useShiftBulkEdit());
    provide(ManHoursStateKey, useManHours());
    provide(BudgetGroupPlanBoardMiscsStateKey, useBudgetGroupPlanBoardMiscs());

    const commonState = inject(CommonStateKey);
    const staffsWithShiftsState = inject(StaffWithShiftsStateKey);
    if (!commonState || !staffsWithShiftsState) {
      throw new Error('State Error');
    }

    const { setWorkPlaceId, initializeCommonState, baseDate, budgetGroup } = commonState;

    const { staffsWithShifts } = staffsWithShiftsState;

    onMounted(async () => {
      setWorkPlaceId(root.$route.params.workplaceId);

      const { masters } = await ensureUserAndMasters(root);
      await initializeCommonState(masters);
    });

    useCreateShiftCsvTemplateProvider({ budgetGroup, dt: baseDate });
    useLoadLogimeterStaffAttendancesProvider({ budgetGroup });

    return {
      staffsWithShifts,
    };
  },
});
