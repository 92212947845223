









































































































































































































import Vue from 'vue';
import { defineComponent, ref, computed, type PropType, watch } from '@vue/composition-api';
import { ModalForm, FormItem } from 'src/components/UIComponents/Form';
import { TextInput } from 'src/components/UIComponents/Inputs/TextInput';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { TimeRangeInput } from 'src/components/UIComponents/Inputs/TimeRangeInput';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { type Validator, useCustomValidator } from 'src/composables/useCustomValidator';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import {
  APPROPRIATION_DATE_DIFF_MAX,
  APPROPRIATION_DATE_DIFF_MIN,
  APPROPRIATION_DATE_DIFF_ANCHOR,
  APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS,
  APPROPRIATION_DATE_RANGE,
  APPROPRIATION_DATE_RANGE_OPTIONS,
} from '../../const';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { type TimeRange, createTimeRange, isStrictTimeRange, containsTimeRange } from 'src/values/TimeRange';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { type TimetableMasterSaveItem } from '../../types/TimetableMasterSaveItem';
import { ColorSelect } from '../ColorSelect';
import { TimetableLabelSelect } from '../TimetableLabelSelect';
import { TimetableMasterLogimeterActivitiesSelect } from '../TimetableMasterLogimeterActivitiesSelect';
import { AppropriationDateDiffAnchorSelect } from '../AppropriationDateDiffAnchorSelect';
import { AppropriationDateRangeSelect } from '../AppropriationDateRangeSelect';
import TimetableMasterInputModalFormGroup from './TimetableMasterInputModalFormGroup.vue';
import TimetableMasterInputModalNumberInput from './TimetableMasterInputModalNumberInput.vue';
import PhaseSelect from './PhaseSelect.vue';
import SplitContainer from './SplitContainer.vue';
import { timeRangeValidator, phasesValidator } from './validators';

const break1TimeWithinWorkTimeValidator: Validator = {
  name: 'break1TimeWithinWorkTime',
  validate(break1Time: unknown, targets) {
    const workTime = Array.isArray(targets) ? targets[0] : null;
    if (!isStrictTimeRange(workTime) || !isStrictTimeRange(break1Time)) {
      return false;
    }
    return containsTimeRange(workTime, break1Time);
  },
  options: { hasTarget: true },
};

const break2TimeWithinWorkTimeValidator: Validator = {
  name: 'break2TimeWithinWorkTime',
  validate(break2Time: unknown, targets) {
    const workTime = Array.isArray(targets) ? targets[0] : null;
    if (!isStrictTimeRange(workTime) || !isStrictTimeRange(break2Time)) {
      return false;
    }
    return containsTimeRange(workTime, break2Time);
  },
  options: { hasTarget: true },
};

const break2TimeAfterOrEqualBreak1TimeValidator: Validator = {
  name: 'break2TimeAfterOrEqualBreak1Time',
  validate(break2Time: unknown, targets) {
    const break1Time = Array.isArray(targets) ? targets[0] : null;
    if (!isStrictTimeRange(break1Time) || !isStrictTimeRange(break2Time)) {
      return false;
    }
    return break2Time.startTime >= break1Time.endTime;
  },
  options: { hasTarget: true },
};

export default defineComponent({
  components: {
    ModalForm,
    FormItem,
    TextInput,
    TimeInput,
    TimeRangeInput,
    ToggleSwitch,
    ColorSelect,
    TimetableLabelSelect,
    TimetableMasterLogimeterActivitiesSelect,
    AppropriationDateDiffAnchorSelect,
    AppropriationDateRangeSelect,
    TimetableMasterInputModalFormGroup,
    TimetableMasterInputModalNumberInput,
    PhaseSelect,
    SplitContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    timetableMaster: {
      type: Object as PropType<TimetableMaster | null>,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<(timetableMasterSaveItem: TimetableMasterSaveItem) => Promise<unknown>>,
      required: true,
    },
  },
  setup(props, context) {
    const root = context.root as Vue;
    const hasRoleSuperAdmin = root.$store.getters['user/hasRoleSuperAdmin'];
    const timeRangeRule = useCustomValidator(timeRangeValidator);
    const break1TimeWithinWorkTimeRule = useCustomValidator(break1TimeWithinWorkTimeValidator);
    const break2TimeWithinWorkTimeRule = useCustomValidator(break2TimeWithinWorkTimeValidator);
    const break2TimeAfterOrEqualBreak1TimeRule = useCustomValidator(break2TimeAfterOrEqualBreak1TimeValidator);
    const break1TimeRules = `${timeRangeRule}|${break1TimeWithinWorkTimeRule}:workTime`;
    const break2TimeRules = `${timeRangeRule}|${break2TimeWithinWorkTimeRule}:workTime|${break2TimeAfterOrEqualBreak1TimeRule}:break1Time`;
    const phasesRule = useCustomValidator(phasesValidator);
    const { timetableLabels } = useTimetableLabels();
    const { budgetGroupTimetableActivityMasters } = useTimetableActivityMasters();
    const name = ref(props.timetableMaster?.name ?? '');
    const phases = ref({
      useInPrepPhase: props.timetableMaster?.use_in_prep_phase ?? false,
      useInDoPhase: props.timetableMaster?.use_in_do_phase ?? false,
      useInReviewPhase: props.timetableMaster?.use_in_review_phase ?? false,
    });
    const plannedProductivity = ref(props.timetableMaster?.planned_productivity ?? null);
    const dispColor = ref(props.timetableMaster?.disp_color ?? null);
    const isEnabled = ref(props.timetableMaster?.is_enabled ?? true);
    const startTimeAndEndTime = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.start_time ?? 0, props.timetableMaster?.end_time ?? 240000),
    );
    const break1StartTimeAndEndTime = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.break1_start_time ?? null, props.timetableMaster?.break1_end_time ?? null),
    );
    const break2StartTimeAndEndTime = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.break2_start_time ?? null, props.timetableMaster?.break2_end_time ?? null),
    );
    const plannedQuantity = ref(props.timetableMaster?.planned_quantity ?? 0);
    const maxAllocations = ref(props.timetableMaster?.max_allocations ?? 0);
    const targetTime = ref(props.timetableMaster?.target_time ?? null);
    const timetableLabel = ref(props.timetableMaster?.timetable_label ?? null);
    const manHourOnly = ref(props.timetableMaster?.man_hour_only ?? false);
    const appropriationDtDiffStart = ref<number>(
      props.timetableMaster?.appropriation_dt_diff_start ?? APPROPRIATION_DATE_DIFF_MIN,
    );
    const appropriationDtDiffEnd = ref<number>(
      props.timetableMaster?.appropriation_dt_diff_end ?? APPROPRIATION_DATE_DIFF_MAX,
    );
    const appropriationDateDiffAnchor = ref<number | null>(null);
    const appropriationDateRange = ref<number>(APPROPRIATION_DATE_RANGE.ON);
    const shouldAggregateTransactions = ref(props.timetableMaster?.should_aggregate_transactions ?? false);
    const activityMasterIds = ref(
      (props.timetableMaster?.timetable_master_logimeter_activities ?? []).map((v) => v.activity_master_id),
    );
    const handleSubmit = async () => {
      await props.onSubmit({
        name: name.value,
        use_in_prep_phase: phases.value.useInPrepPhase,
        use_in_do_phase: phases.value.useInDoPhase,
        use_in_review_phase: phases.value.useInReviewPhase,
        planned_productivity: plannedProductivity.value!,
        disp_color: dispColor.value!,
        is_enabled: isEnabled.value,
        start_time: startTimeAndEndTime.value?.startTime!,
        end_time: startTimeAndEndTime.value?.endTime!,
        break1_start_time: break1StartTimeAndEndTime.value?.startTime ?? null,
        break1_end_time: break1StartTimeAndEndTime.value?.endTime ?? null,
        break2_start_time: break2StartTimeAndEndTime.value?.startTime ?? null,
        break2_end_time: break2StartTimeAndEndTime.value?.endTime ?? null,
        planned_quantity: plannedQuantity.value,
        max_allocations: maxAllocations.value,
        target_time: targetTime.value,
        timetable_label: timetableLabel.value!,
        should_aggregate_transactions: shouldAggregateTransactions.value,
        man_hour_only: manHourOnly.value,
        activity_master_ids: activityMasterIds.value,
        appropriation_dt_diff_start: appropriationDtDiffStart.value,
        appropriation_dt_diff_end: appropriationDtDiffEnd.value,
      });
    };

    // appropriation_dt_diff_startとappropriation_dt_diff_endから「特定の計上日に絞り込み」を算出する
    const computeAppropriationDate = (timetableMaster: TimetableMaster): void => {
      // appropriation_dt_diff_start:null, appropriation_dt_diff_end:null or
      // appropriation_dt_diff_start:-32, appropriation_dt_diff_end:32の時
      // 未設定なので「特定の計上日に絞り込み」は設定なしと判断する
      if (
        (timetableMaster.appropriation_dt_diff_start === null && timetableMaster.appropriation_dt_diff_end === null) ||
        (timetableMaster.appropriation_dt_diff_start === APPROPRIATION_DATE_DIFF_MIN &&
          timetableMaster.appropriation_dt_diff_end === APPROPRIATION_DATE_DIFF_MAX)
      ) {
        return;
      }
      // appropriation_dt_diff_startとappropriation_dt_diff_endが同じ値の時
      // 「特定の計上日に絞り込み」左プルダウン：選択済、「特定の計上日に絞り込み」右プルダウン：- と判断する
      if (timetableMaster.appropriation_dt_diff_start === timetableMaster.appropriation_dt_diff_end) {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_start;
        appropriationDateRange.value = APPROPRIATION_DATE_RANGE.ON;
        return;
      }

      if (Object.values(APPROPRIATION_DATE_DIFF_ANCHOR).includes(timetableMaster.appropriation_dt_diff_start!)) {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_start;
        appropriationDateRange.value =
          timetableMaster.appropriation_dt_diff_end! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      } else {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_end;
        appropriationDateRange.value =
          timetableMaster.appropriation_dt_diff_start! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      }
    };
    if (props.timetableMaster) {
      computeAppropriationDate(props.timetableMaster);
    }

    // 「特定の計上日に絞り込み」からappropriation_dt_diff_startとappropriation_dt_diff_endを算出する
    const computeAppropriationDtDiff = () => {
      // 「特定の計上日に絞り込み」が設定なしの時
      // デフォルト値 appropriation_dt_diff_start:-32, appropriation_dt_diff_end:32とする
      if (!appropriationDateDiffAnchor.value && appropriationDateDiffAnchor.value !== 0) {
        appropriationDtDiffStart.value = APPROPRIATION_DATE_DIFF_MIN;
        appropriationDtDiffEnd.value = APPROPRIATION_DATE_DIFF_MAX;
      } else if (appropriationDateRange.value === APPROPRIATION_DATE_RANGE.ON) {
        // 「特定の計上日に絞り込み」右プルダウン：- の時
        // appropriation_dt_diff_start・appropriation_dt_diff_endともに、「特定の計上日に絞り込み」左プルダウンの値とする
        appropriationDtDiffStart.value = appropriationDateDiffAnchor.value;
        appropriationDtDiffEnd.value = appropriationDateDiffAnchor.value;
      } else if (appropriationDateRange.value === APPROPRIATION_DATE_RANGE.ON_AND_BEFORE) {
        // 「特定の計上日に絞り込み」右プルダウン：以前 の時
        appropriationDtDiffStart.value = APPROPRIATION_DATE_DIFF_MIN;
        appropriationDtDiffEnd.value = appropriationDateDiffAnchor.value;
      } else {
        // 「特定の計上日に絞り込み」右プルダウン：以降 の時
        appropriationDtDiffStart.value = appropriationDateDiffAnchor.value;
        appropriationDtDiffEnd.value = APPROPRIATION_DATE_DIFF_MAX;
      }
    };

    // 「特定の計上日に絞り込み」右プルダウンの活性化・非活性化処理
    const isAppropriationDateRangeOptionDisabled = computed(() => {
      return typeof appropriationDateDiffAnchor.value !== 'number';
    });

    watch(
      () => appropriationDateDiffAnchor.value,
      () => {
        computeAppropriationDtDiff();
      },
    );

    watch(
      () => appropriationDateRange.value,
      () => {
        computeAppropriationDtDiff();
      },
    );

    watch(
      () => isAppropriationDateRangeOptionDisabled.value,
      () => {
        if (isAppropriationDateRangeOptionDisabled.value) {
          appropriationDateRange.value = APPROPRIATION_DATE_RANGE.ON;
        }
      },
    );

    return {
      hasRoleSuperAdmin,
      timeRangeRule,
      break1TimeRules,
      break2TimeRules,
      phasesRule,
      timetableLabels,
      budgetGroupTimetableActivityMasters,
      APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS,
      APPROPRIATION_DATE_RANGE_OPTIONS,
      isAppropriationDateRangeOptionDisabled,
      name,
      phases,
      plannedProductivity,
      dispColor,
      isEnabled,
      startTimeAndEndTime,
      break1StartTimeAndEndTime,
      break2StartTimeAndEndTime,
      plannedQuantity,
      maxAllocations,
      targetTime,
      timetableLabel,
      manHourOnly,
      shouldAggregateTransactions,
      activityMasterIds,
      appropriationDateDiffAnchor,
      appropriationDateRange,
      handleSubmit,
    };
  },
});
