import { reactive } from '@vue/composition-api';
import { useNotification } from 'src/composables/useNotification';
import { createInjection } from 'src/util/createInjection';
import { DailySimulationCommonSettingState } from '../types';
import { useModal } from 'src/composables/useModal';

type InjectionValue = {
  commonSetting: DailySimulationCommonSettingState;
  showsCommonSettingModal: ReturnType<typeof useModal>['showsModal'];
  showCommonSettingModal: ReturnType<typeof useModal>['showModal'];
  hideCommonSettingModal: ReturnType<typeof useModal>['hideModal'];
  setCommonSetting: (state: DailySimulationCommonSettingState) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useCommonSetting');

export function useCommonSettingProvider(): void {
  const {
    showsModal: showsCommonSettingModal,
    showModal: showCommonSettingModal,
    hideModal: hideCommonSettingModal,
  } = useModal();
  const { notifySuccess } = useNotification();
  const commonSetting: DailySimulationCommonSettingState = reactive({
    targetClockOutTime: 180000,
    break1TimeRange: {
      startTime: null,
      endTime: null,
    },
    break2TimeRange: {
      startTime: null,
      endTime: null,
    },
  });
  const setCommonSetting = (nextCommonSetting: DailySimulationCommonSettingState) => {
    commonSetting.targetClockOutTime = nextCommonSetting.targetClockOutTime;
    if (
      commonSetting.break1TimeRange.startTime !== nextCommonSetting.break1TimeRange?.startTime ||
      commonSetting.break1TimeRange.endTime !== nextCommonSetting.break1TimeRange?.endTime
    ) {
      commonSetting.break1TimeRange = {
        startTime: nextCommonSetting.break1TimeRange?.startTime ?? null,
        endTime: nextCommonSetting.break1TimeRange?.endTime ?? null,
      };
    }
    if (
      commonSetting.break2TimeRange.startTime !== nextCommonSetting.break2TimeRange?.startTime ||
      commonSetting.break2TimeRange.endTime !== nextCommonSetting.break2TimeRange?.endTime
    ) {
      commonSetting.break2TimeRange = {
        startTime: nextCommonSetting.break2TimeRange?.startTime ?? null,
        endTime: nextCommonSetting.break2TimeRange?.endTime ?? null,
      };
    }
    notifySuccess('共通設定を更新しました');
  };

  provide({
    commonSetting,
    showsCommonSettingModal,
    showCommonSettingModal,
    hideCommonSettingModal,
    setCommonSetting,
  });
}

export function useCommonSetting(): InjectionValue {
  return inject();
}
