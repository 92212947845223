export default class Sort {
  public readonly state: any
  public readonly target: string
  public sortObjects: { key: string, asc: boolean, forceOrder: boolean}[] | null

  constructor(state: any, target: string, sortObjects: { key: string, asc: boolean, forceOrder: boolean }[] | null) {
    this.state = state
    this.target = target
    this.sortObjects = sortObjects
  }

  sortBy(sortObjects: { key: string, asc: boolean, forceOrder: boolean }[]): void {
    if (!this.state[this.target]) {
      return
    }
    if (sortObjects === null) {
      return
    }
    const self = this
    this.state[this.target].sort(function(a: any, b: any) {
      let item = 0
      let ret = 0
      while (item < sortObjects.length && ret === 0) {
        if (self.sortObjects !== null) {
          const flipTarget = self.sortObjects.find(d => d.key === sortObjects[item].key)
          if (flipTarget && !sortObjects[item].forceOrder) {
            sortObjects[item].asc = !flipTarget.asc
          }
        }
        // convert dotted string to object
        const aItem = sortObjects[item].key.split('.').reduce((o, i) => o[i], a)
        const bItem = sortObjects[item].key.split('.').reduce((o, i) => o[i], b)
        const aValue = isNaN(aItem) ? aItem : +aItem
        const bValue = isNaN(bItem) ? bItem : +bItem
        ret = aValue < bValue ? -1 : (aValue > bValue ? 1 : 0)
        if (!sortObjects[item].asc) {
          ret = ret * -1
        }
        item++
      }
      return ret
    })
    this.sortObjects = sortObjects
  }
}
