import { ref, readonly, type Ref, type DeepReadonly, watch, reactive, computed } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { isExist } from 'src/util/isExist';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { useProgressHeaders } from './useProgressHeaders';
import { useCommonSetting } from './useCommonSetting';
import {
  DAILY_SIMULATION_COLUMN_WIDTH,
  DAILY_SIMULATION_REMAINING_HEADCOUNT_HOUR_BLOCK_ID,
  DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID,
} from '../const';
import {
  DailySimulationHourBlock,
  DailySimulationTimeBlock,
  DailySimulationTimetableRow,
  ProgressHistory,
  SaveProgressDetailCandidate,
} from '../types';
import {
  createInitialHourBlocks,
  createTimetableFromApiResponse,
  createTotalHourBlocksFromDailySimulationTimetable,
  getBlockLengthPerHour,
  getLastHeadcountExistTime,
  getTimetableMastersWithProgressHeader,
} from '../dailySimulationHelper';
import { ValidationObserverInstance } from 'vee-validate';
import {
  formatTimeInteger,
  packToTimeInteger,
  secondsToTimeInteger,
  timeIntegerToHours,
  timeIntegerToSeconds,
  timeStrToTimeInteger,
  unpackTimeInteger,
} from 'src/util/datetime';
import { TimeInteger } from 'src/models/common';
import { TimeRange } from 'src/values/TimeRange';
import { useBudgetGroupPlanBoardMisc } from './useBudgetGroupPlanBoardMiscs';
import { useDisplayConditions } from './useDisplayConditions';

const DISPLAY_HOUR_PERIOD = 48; // 表示したい時間数: 現状外部からの指定はないので 48 固定

type DailySimulationState = {
  timetable: DailySimulationTimetableRow[];
  totalHourBlocks: DailySimulationHourBlock[]; // 単位時間ごとの合計人時を 1 時間ごとの配列で持つ配列
  remainingHeadcountHourBlocks: DailySimulationHourBlock[];
  blockLengthPerHour: number; // 1 時間内の単位時間数 例) 単位時間が 15 分の場合 4
  displayTimes: string[];
  unitMinutes: number; // 単位時間分
  lastHeadcountExistTime: TimeInteger;
  isLoading: boolean;
};

type Params = {
  validationObserver: Ref<ValidationObserverInstance | undefined>;
};

type InjectionValue = {
  state: DeepReadonly<DailySimulationState>;
  displayedTimetable: Ref<DailySimulationTimetableRow[]>;
  timetableRowStyle: {
    gridTemplateColumns: string;
    display: string;
  };
  simulateTimetable: (simulationStartTime: string) => void;
  calculateRemainingTimeBlocks: (simulationStartTime: string) => void;
  updateHeadcountAndSimulateTimetableRow: (timeBlock: DailySimulationTimeBlock, nextHeadcount: number) => void;
  getProgressHistoriesStyle: (progressHistories: ProgressHistory[]) => {
    display: string;
    gridTemplateColumns?: string;
  };
  fetchAndSimulate: (option?: { isResetHeader: boolean; isResetHeadcount: boolean }) => Promise<void>;
  fetchAndNoSimulate: () => void;
  fetchAndSimulateTimetableRow: (timetableMasterId: number) => void;
  resetSimulation: () => void;
  setTotalProductivityToPlanedProductivity: (targetTimetableRows: DailySimulationTimetableRow[]) => void;
  isBreakTime: (displayTime: string) => boolean;
  isPreviousSimulated: (currentTimeBlock: DailySimulationTimeBlock) => boolean;
  isHeadcountSameAsPreviousTime: (currentTimeBlock: DailySimulationTimeBlock) => boolean;
  saveProgressDetailCandidate: SaveProgressDetailCandidate;
  waitLoading: () => void;
  finishLoading: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useDailySimulations');

export function useDailySimulationProvider({ validationObserver }: Params): void {
  const state: DailySimulationState = reactive({
    timetable: [],
    totalHourBlocks: [],
    remainingHeadcountHourBlocks: [],
    blockLengthPerHour: 4,
    unitMinutes: computed(() => 60 / state.blockLengthPerHour),
    lastHeadcountExistTime: 0,
    displayTimes: computed(() => {
      return state.timetable[0].hourBlocks.map(({ unitTimeBlocks }) => unitTimeBlocks[0].displayTime);
    }),
    isLoading: false,
  });
  const { timetableMasterMap, timetableMasters } = useTimetableMasters();
  const { progressHeaders, fetchProgressHeaders } = useProgressHeaders();
  const { setBudgetGroupPlanBoardMisc } = useBudgetGroupPlanBoardMisc();
  const { commonSetting } = useCommonSetting();
  const { filteredTimetableMasterIds, filterTimetable } = useDisplayConditions();
  const saveProgressDetailCandidate: SaveProgressDetailCandidate = { timetableMasters: [] };
  const isNoSimulate = ref(false);
  const isResetHeader = ref(false);
  const resetHeadcountTargetIds = ref<number[]>([]);
  const isInvalidInputExist = computed(() => {
    if (!isExist(validationObserver.value)) {
      return false;
    }
    return Object.values(validationObserver.value.ctx.errors).some((error) => error.length > 0);
  });
  const allTimetableMasterIds = computed(() => {
    return state.timetable.map(({ header }) => header.masterId).concat(DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID);
  });

  const timetableRowMap = computed<Record<number, DailySimulationTimetableRow>>(() => {
    return state.timetable.reduce<Record<number, DailySimulationTimetableRow>>((map, timetableRow) => {
      return {
        ...map,
        [timetableRow.header.masterId]: timetableRow,
      };
    }, {});
  });

  const hourBlocksMap = computed<Record<number, DailySimulationHourBlock[]>>(() => ({
    ...Object.entries(timetableRowMap.value).reduce<Record<number, DailySimulationHourBlock[]>>(
      (map, [key, timetableRow]) => {
        return {
          ...map,
          [key]: timetableRow.hourBlocks,
        };
      },
      {},
    ),
    [DAILY_SIMULATION_REMAINING_HEADCOUNT_HOUR_BLOCK_ID]: state.remainingHeadcountHourBlocks,
    [DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID]: state.totalHourBlocks,
  }));

  const displayedTimetable = computed(() => {
    return state.timetable.filter((timetableRow) =>
      filteredTimetableMasterIds.value.includes(timetableRow.header.masterId),
    );
  });

  const timetableRowStyle = {
    gridTemplateColumns: `repeat(${DISPLAY_HOUR_PERIOD}, ${DAILY_SIMULATION_COLUMN_WIDTH}px)`,
    display: 'grid',
  };

  watch([progressHeaders], () => {
    resetSimulation();
  });

  watch(
    () => [commonSetting.break1TimeRange, commonSetting.break2TimeRange],
    () => {
      resetTimetable(false, allTimetableMasterIds.value);
      simulateTimetable('0:00', allTimetableMasterIds.value);
    },
  );

  watch(
    () => [commonSetting.targetClockOutTime],
    () => {
      resetTimetable(false, [DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID]);
      simulateTimetable('0:00', [DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID]);
    },
  );

  const resetTimetable = (isResetHeader: boolean, resetHeadcountTargetIds: number[]) => {
    state.blockLengthPerHour = getBlockLengthPerHour(progressHeaders.value);
    const timetable = createTimetableFromApiResponse(
      progressHeaders.value,
      DISPLAY_HOUR_PERIOD,
      timetableMasterMap.value,
    );
    state.timetable = copyTimetable(state.timetable, timetable, {
      isResetHeader,
      resetHeadcountTargetIds,
    });
    state.lastHeadcountExistTime = getLastHeadcountExistTime(progressHeaders.value, DISPLAY_HOUR_PERIOD);
    const totalHourBlocks = createTotalHourBlocksFromDailySimulationTimetable(state.timetable, DISPLAY_HOUR_PERIOD);
    state.totalHourBlocks = resetHeadcountTargetIds.includes(DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID)
      ? totalHourBlocks
      : copyTotalHourBlocks(state.totalHourBlocks, totalHourBlocks);
    saveProgressDetailCandidate.timetableMasters = getTimetableMastersWithProgressHeader(
      timetableMasters.value,
      progressHeaders.value,
    );
    state.remainingHeadcountHourBlocks = createInitialHourBlocks(
      state.blockLengthPerHour,
      DISPLAY_HOUR_PERIOD,
      DAILY_SIMULATION_REMAINING_HEADCOUNT_HOUR_BLOCK_ID,
    );
  };

  /**
   * DailySimulationHeader や DailySimulationHourBlock[] を現在の値で上書く
   */
  const copyTimetable = (
    from: DailySimulationTimetableRow[],
    to: DailySimulationTimetableRow[],
    option: {
      isResetHeader: boolean;
      resetHeadcountTargetIds: number[];
    },
  ): DailySimulationTimetableRow[] => {
    const fromTimetableRowMap = from.reduce<Record<string, DailySimulationTimetableRow>>(
      (timetableRowMap, timetableRow) => {
        return {
          ...timetableRowMap,
          [timetableRow.header.masterId]: timetableRow,
        };
      },
      {},
    );

    const timetable = option.isResetHeader
      ? to
      : to.concat().map((timetableRow) => {
          const reusedHeader = fromTimetableRowMap[timetableRow.header.masterId].header;
          const header = { ...timetableRow.header };
          if (isExist(reusedHeader)) {
            header.planedProductivity = reusedHeader.planedProductivity;
          }
          return {
            ...timetableRow,
            header,
          };
        });

    timetable.forEach((timetableRow) => {
      if (option.resetHeadcountTargetIds.includes(timetableRow.header.masterId)) {
        return;
      }
      const fromTimetableRow = fromTimetableRowMap[timetableRow.header.masterId];
      if (!isExist(fromTimetableRow)) {
        return;
      }
      const lastHeadcountExistTime = getLastHeadcountExistTime([timetableRow.progressHeader], DISPLAY_HOUR_PERIOD);
      const startTimeBlock = getTimeBlock(fromTimetableRow.hourBlocks, lastHeadcountExistTime);
      if (isExist(startTimeBlock)) {
        copyTimeBlock(startTimeBlock, timetableRow.hourBlocks);
      }
    });
    return timetable;
  };

  const copyTimeBlock = (fromTimeBlock: DailySimulationTimeBlock, toHourBlocks: DailySimulationHourBlock[]) => {
    const nextTimeBlock = getNextTimeBlock(fromTimeBlock);
    const toTimeBlock = getTimeBlock(toHourBlocks, fromTimeBlock.displayTime);
    if (!isExist(toTimeBlock)) {
      if (isExist(nextTimeBlock)) {
        copyTimeBlock(nextTimeBlock, toHourBlocks);
      }
      return;
    }

    toTimeBlock.headcount = fromTimeBlock.headcount;
    toTimeBlock.isSimulated = fromTimeBlock.isSimulated;
    toTimeBlock.isHeadcountShrunk = fromTimeBlock.isHeadcountShrunk;

    if (isExist(nextTimeBlock)) {
      copyTimeBlock(nextTimeBlock, toHourBlocks);
    }
  };

  const copyTotalHourBlocks = (
    fromHourBlocks: DailySimulationHourBlock[],
    toHourBlocks: DailySimulationHourBlock[],
  ) => {
    const returnHourBlocks = toHourBlocks.concat();
    const startTimeBlock = getTimeBlock(fromHourBlocks, state.lastHeadcountExistTime);
    if (isExist(startTimeBlock)) {
      copyTimeBlock(startTimeBlock, returnHourBlocks);
    }
    return returnHourBlocks;
  };

  /**
   * シミュレーションを実行し、ProgressHistoryを更新する
   *
   * シミュレーションの場合、ProgressHistoryは時間(h)ごとにデータが作成される
   *
   * シミュレーションは3つのステップに分かれている
   * ステップ1:
   *   DailySimulationTimetableRowの中から、引数で渡されたシミュレーション開始点より後の
   *   以前のシミュレーションによって生成されたProgressHistoryをクリアする
   * ステップ2:
   *   シミュレーション開始点の直前のProgressHistoryがシミュレートされたものである場合
   *   そのProgressHistoryをシミュレート開始点までの長さに加工する
   * ステップ3:
   *   シミュレーション開始点以降のProgressHistoryをシミュレートされたものに書き変える
   */
  const simulateTimetable = (simulationStartTime: string, resetHeadcountTargetIds: number[] = []) => {
    if (isInvalidInputExist.value) {
      return;
    }

    state.timetable.forEach((timetableRow) => {
      simulateTimetableRow(
        timetableRow,
        simulationStartTime,
        resetHeadcountTargetIds.includes(timetableRow.header.masterId),
      );
    });
    simulateTotalHourBlocks(
      simulationStartTime,
      resetHeadcountTargetIds.includes(DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID),
    );
    calculateRemainingTimeBlocks(simulationStartTime);
  };

  /**
   * 1 つの工程に対してシミュレーションを実行する
   */
  const simulateTimetableRow = (
    timetableRow: DailySimulationTimetableRow,
    simulationStartTime: string,
    isResetHeadcount: boolean,
    headcountChange?: number, // 人数変更が行われた際の、各時間帯に追加(削減)する差分人数
  ) => {
    // ステップ1: 引数で渡されたシミュレーション開始点以降の時刻のDailySimulationTimetableRow内のProgressHistoryをクリアする
    // ステップ2: 条件によりシミュレーション開始点の直前のProgressHistoryを更新する
    resetTimetableRow(timetableRow, simulationStartTime);

    // ステップ3: シミュレーション開始点以降のProgressHistoryをシミュレートされたものに書き変える

    const simulationStartTimeBlock = getTimeBlock(timetableRow.hourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock)) {
      simulateTimeBlock(simulationStartTimeBlock, isResetHeadcount, headcountChange, true);
    }
  };

  const simulateTimeBlock = (
    currentTimeBlock: DailySimulationTimeBlock,
    isResetHeadcount: boolean,
    headcountChange?: number, // 人数変更が行われた際の、各時間帯に追加(削減)する差分人数
    isFirstSimulate = false, // シミュレーション対象のブロックがシミュレーション基準点移行最初のブロックである場合は処理条件が変わる為、フラグを用意する
  ) => {
    const { lastHeadcountExistTime } = state;
    const currentTime = getTimeInteger(currentTimeBlock);
    const currentTimeStr = formatTimeInteger(currentTime);
    const nextTimeBlock = getNextTimeBlock(currentTimeBlock);
    currentTimeBlock.isSimulated = false;

    // 休憩時間の場合 -> シミュレーションの人数を 0 人にし、進捗履歴の更新をして次の時間帯へ
    if (isBreakTime(currentTimeStr)) {
      if (lastHeadcountExistTime <= currentTime) {
        currentTimeBlock.headcount = 0;
      }
      upsertProgressHistory(currentTimeBlock);

      if (isExist(nextTimeBlock)) {
        simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange);
      }
      return;
    }

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）-> 進捗履歴の更新だけして次の時間帯へ
    if (currentTime < lastHeadcountExistTime) {
      upsertProgressHistory(currentTimeBlock);
      if (isExist(nextTimeBlock)) {
        simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange);
      }
      return;
    }

    // 休憩時間ではない && 実績が記録されていない 時間帯の場合 -> 必要人数の算出を行い、人数を割り当てていく
    const requiredHeadcount = getRequiredHeadcount(currentTimeBlock);
    let simulatedHeadcount = simulateHeadcount(currentTimeBlock, isResetHeadcount, isFirstSimulate, headcountChange);

    const isJustChangedTimeBlock = isFirstSimulate && isExist(headcountChange);
    if (requiredHeadcount < simulatedHeadcount && !isJustChangedTimeBlock) {
      simulatedHeadcount = requiredHeadcount;
      // 必要人時がシミュレーション結果の数値よりも小さい場合は、人数が縮小されたフラグを立て、次回のシミュレーション時に利用する。
      // ただし、requiredHeadcount が 0 の場合は、縮小ではなく完了とみなすので、フラグは立てない。
      currentTimeBlock.isHeadcountShrunk = requiredHeadcount > 0;
    } else {
      currentTimeBlock.isHeadcountShrunk = false;
    }

    currentTimeBlock.headcount = Math.max(0, simulatedHeadcount);
    currentTimeBlock.isSimulated = true;

    upsertProgressHistory(currentTimeBlock);

    if (isExist(nextTimeBlock)) {
      simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange);
    }
  };

  const getRequiredHeadcount = (currentTimeBlock: DailySimulationTimeBlock) => {
    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];
    const currentTime = getTimeInteger(currentTimeBlock);
    const blockProductivity = getBlockProductivity(timetableRow);
    const { scheduledQuantity, targetCompletionTime } = timetableRow.header;
    const lastProgressHistory = timetableRow.progressHistories[timetableRow.progressHistories.length - 1] as
      | ProgressHistory
      | undefined;
    if (!isExist(scheduledQuantity)) {
      if (!isExist(targetCompletionTime) || currentTime < targetCompletionTime) {
        return Number.MAX_SAFE_INTEGER;
      } else {
        return 0;
      }
    } else {
      const completedQuantity = lastProgressHistory?.totalQuantity ?? 0;
      return Math.ceil(Math.max(0, scheduledQuantity - completedQuantity) / blockProductivity);
    }
  };

  const simulateHeadcount = (
    currentTimeBlock: DailySimulationTimeBlock,
    isResetHeadcount: boolean,
    isFirstSimulate: boolean,
    headcountChange?: number,
  ) => {
    const lastHeadcount = getLastHeadcount(currentTimeBlock);
    if (!isResetHeadcount) {
      if (currentTimeBlock.isHeadcountShrunk) {
        return lastHeadcount;
      } else if (lastHeadcount > 0 && currentTimeBlock.headcount === 0) {
        return lastHeadcount;
      }
      return currentTimeBlock.headcount;
    }
    if (isExist(headcountChange)) {
      if (isFirstSimulate) {
        return currentTimeBlock.headcount + headcountChange;
      } else if (currentTimeBlock.isHeadcountShrunk) {
        return lastHeadcount;
      } else if (lastHeadcount > 0 && currentTimeBlock.headcount === 0) {
        return lastHeadcount;
      } else {
        return currentTimeBlock.headcount + headcountChange;
      }
    }
    return lastHeadcount;
  };

  /**
   * 余剰人数を算出する
   * ユーザーが入力した「合計」人数から、「現在表示中の各工程の人数の合計値」を引いた値を表示するためのもの
   * 各時間帯において、ユーザーが想定している人数とシミュレーションによる人数の差分をわかりやすくすることで、シミュレーションの数値を編集する際の参考値にしてもらう意図がある
   */
  const calculateRemainingTimeBlocks = (simulationStartTime: string) => {
    const simulationStartTimeBlock = getTimeBlock(state.remainingHeadcountHourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock)) {
      calculateRemainingTimeBlock(simulationStartTimeBlock);
    }
  };

  const calculateRemainingTimeBlock = (currentRemainingHeadcountHourBlock: DailySimulationTimeBlock) => {
    const { lastHeadcountExistTime } = state;
    const currentTime = getTimeInteger(currentRemainingHeadcountHourBlock);
    const nextTimeBlock = getNextTimeBlock(currentRemainingHeadcountHourBlock);

    currentRemainingHeadcountHourBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      calculateRemainingTimeBlock(nextTimeBlock);
      return;
    }

    currentRemainingHeadcountHourBlock.isSimulated = true;

    const totalHeadcount = state.timetable.reduce((totalHeadcount, timetableRow) => {
      return totalHeadcount + (getTimeBlock(timetableRow.hourBlocks, currentTime)?.headcount ?? 0);
    }, 0);
    const currentTotalHourBlock = getTimeBlock(state.totalHourBlocks, currentTime);
    if (isExist(currentTotalHourBlock)) {
      currentRemainingHeadcountHourBlock.headcount = currentTotalHourBlock.headcount - totalHeadcount;
    }

    if (isExist(nextTimeBlock)) {
      calculateRemainingTimeBlock(nextTimeBlock);
    }
  };

  /**
   * 合計人数をシミュレーションする
   */
  const simulateTotalHourBlocks = (
    simulationStartTime: string,
    isResetHeadcount: boolean,
    headcountChange?: number,
  ) => {
    const simulationStartTimeBlock = getTimeBlock(state.totalHourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock) && isResetHeadcount) {
      simulateTotalTimeBlock(simulationStartTimeBlock, headcountChange, true);
    }
  };

  const simulateTotalTimeBlock = (
    currentTotalTimeBlock: DailySimulationTimeBlock,
    headcountChange?: number,
    isFirstSimulate = false,
  ) => {
    const currentTime = getTimeInteger(currentTotalTimeBlock);
    const nextTimeBlock = getNextTimeBlock(currentTotalTimeBlock);
    const { lastHeadcountExistTime } = state;

    currentTotalTimeBlock.isSimulated = false;

    // 休憩時間の場合 -> シミュレーションの人数を 0 人にして次の時間帯へ
    if (isBreakTime(currentTotalTimeBlock.displayTime)) {
      if (lastHeadcountExistTime <= currentTime) {
        currentTotalTimeBlock.headcount = 0;
      }
      if (isExist(nextTimeBlock)) {
        simulateTotalTimeBlock(nextTimeBlock, headcountChange);
        return;
      }
    }

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      simulateTotalTimeBlock(nextTimeBlock, headcountChange);
      return;
    }

    currentTotalTimeBlock.isSimulated = true;

    const isBeforeClockOut =
      isExist(commonSetting.targetClockOutTime) && currentTime < commonSetting.targetClockOutTime;
    if (isExist(headcountChange)) {
      if (isFirstSimulate || isBeforeClockOut) {
        currentTotalTimeBlock.headcount = Math.max(0, currentTotalTimeBlock.headcount + headcountChange);
      }
    } else if (isBeforeClockOut) {
      currentTotalTimeBlock.headcount = getLastHeadcount(currentTotalTimeBlock);
    }
    if (isExist(nextTimeBlock)) {
      simulateTotalTimeBlock(nextTimeBlock, headcountChange);
    }
  };

  /**
   * 人数を更新しつつシミュレーションを実行する
   */
  const updateHeadcountAndSimulateTimetableRow = (timeBlock: DailySimulationTimeBlock, nextHeadcount: number) => {
    if (timeBlock.timetableMasterId === DAILY_SIMULATION_TOTAL_HOUR_BLOCK_ID) {
      simulateTotalHourBlocks(timeBlock.displayTime, true, nextHeadcount - timeBlock.headcount);
    } else {
      const timetableRow = state.timetable.find(
        (timetableRow) => timetableRow.header.masterId === timeBlock.timetableMasterId,
      );
      if (!isExist(timetableRow)) {
        return;
      }
      simulateTimetableRow(timetableRow, timeBlock.displayTime, true, nextHeadcount - timeBlock.headcount);
    }
    calculateRemainingTimeBlocks(timeBlock.displayTime);
  };

  /**
   * 直前の休憩ではない人時実績を取得する
   */
  const getLastHeadcount = (currentTimeBlock: DailySimulationTimeBlock) => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    const [targetHour, targetBlockIndex] = getHourAndBlockIndex(currentTimeBlock);

    let lastHour = targetBlockIndex === 0 ? targetHour - 1 : targetHour;
    let lastBlockIndex = targetBlockIndex === 0 ? state.blockLengthPerHour - 1 : targetBlockIndex - 1;

    while (isBreakTime(`${lastHour}:${lastBlockIndex * state.unitMinutes}`)) {
      lastHour = lastBlockIndex === 0 ? lastHour - 1 : lastHour;
      lastBlockIndex = lastBlockIndex === 0 ? state.blockLengthPerHour - 1 : lastBlockIndex - 1;
    }
    const { headcount } = hourBlocks[lastHour]?.unitTimeBlocks[lastBlockIndex] ?? {
      headcount: 0,
    };
    return headcount;
  };

  /**
   * 進捗履歴を TimeBlock 単位で追加更新する
   */
  const upsertProgressHistory = (currentTimeBlock: DailySimulationTimeBlock) => {
    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];
    const blockProductivity = getBlockProductivity(timetableRow);
    const targetQuantity = currentTimeBlock.headcount * blockProductivity;
    const targetTime = getTimeInteger(currentTimeBlock);
    const progressHistories = timetableRow.progressHistories;
    const productivity = timetableRow.header.planedProductivity ?? 0;
    const { scheduledQuantity, targetCompletionTime } = timetableRow.header;
    const unitSeconds = Math.ceil((60 * 60) / state.blockLengthPerHour); // 1 TimeBlock 毎の時間
    const targetTimeSeconds = timeIntegerToSeconds(targetTime);
    const lastProgressHistory = progressHistories[timetableRow.progressHistories.length - 1];
    let quantityToAdd = targetQuantity;
    let secondsToAdd = unitSeconds;

    if (!isExist(scheduledQuantity) && isExist(targetCompletionTime) && targetCompletionTime <= targetTime) {
      // 予定作業量 がない and 目標時刻 がある and 目標時刻 を過ぎている場合 -> 何もしない
      return;
    }

    const lastProgressHistoryEndSeconds = isExist(lastProgressHistory)
      ? timeIntegerToSeconds(lastProgressHistory.endTime)
      : null;
    if (isExist(lastProgressHistoryEndSeconds) && lastProgressHistoryEndSeconds < targetTimeSeconds + unitSeconds) {
      if (isExist(scheduledQuantity) && lastProgressHistory.totalQuantity >= scheduledQuantity) {
        // すでに 予定作業量 を満たしている場合 -> 何もしない
        return;
      }
      if (targetTimeSeconds < lastProgressHistoryEndSeconds) {
        // 途中まで作業が進んでいる場合 -> 追加する数値を調整する
        secondsToAdd = Math.ceil(targetTimeSeconds + unitSeconds - lastProgressHistoryEndSeconds);
        quantityToAdd = (targetQuantity * secondsToAdd) / unitSeconds;
      }
      if (isExist(scheduledQuantity)) {
        const exceededQuantity = lastProgressHistory.totalQuantity + quantityToAdd - scheduledQuantity;
        if (exceededQuantity >= 0) {
          // 今回追加することで 予定作業量 を超える場合 -> 追加する数値を調整する
          quantityToAdd = quantityToAdd - exceededQuantity;
          secondsToAdd = Math.ceil(unitSeconds * (quantityToAdd / targetQuantity));
          const endMinutes = Math.ceil((targetTimeSeconds + secondsToAdd) / 60);
          timetableRow.header.simulatedCompletionTime = packToTimeInteger(
            Math.floor(endMinutes / 60),
            endMinutes % 60,
            0,
          );
        }
      }
    }

    if (!isExist(lastProgressHistoryEndSeconds)) {
      // 進捗履歴が存在しない場合
      if (quantityToAdd > 0) {
        // 人数実績がある場合 -> 進捗履歴を作成
        progressHistories.push({
          quantity: quantityToAdd,
          totalQuantity: quantityToAdd,
          productivity,
          startTime: secondsToTimeInteger(targetTimeSeconds),
          endTime: secondsToTimeInteger(targetTimeSeconds + secondsToAdd),
          isSimulated: true,
        });
      }
    } else if (lastProgressHistoryEndSeconds < targetTimeSeconds + unitSeconds) {
      // 進捗履歴が最後に記録された時刻 <= 処理中のブロックの時刻
      if (targetTime % 10000 === 0 || !lastProgressHistory.isSimulated) {
        // 区切りの時間の場合 or 直前の進捗履歴がシミュレーションによるものではない場合 -> 進捗履歴を追加
        progressHistories.push({
          quantity: quantityToAdd,
          totalQuantity: lastProgressHistory.totalQuantity + quantityToAdd,
          productivity,
          startTime: secondsToTimeInteger(lastProgressHistoryEndSeconds),
          endTime: secondsToTimeInteger(lastProgressHistoryEndSeconds + secondsToAdd),
          isSimulated: true,
        });
      } else {
        // 直前の進捗履歴がシミュレーションによるもの and 2 個目以降の TimeBlock の場合 -> 進捗履歴を更新
        lastProgressHistory.quantity += quantityToAdd;
        lastProgressHistory.totalQuantity += quantityToAdd;
        lastProgressHistory.endTime = secondsToTimeInteger(
          timeIntegerToSeconds(lastProgressHistory.endTime) + secondsToAdd,
        );
      }
    }
  };

  /**
   * 指定した時間以降の進捗履歴を削除 & 終了予測の初期化
   */
  const resetTimetableRow = (timetableRow: DailySimulationTimetableRow, targetTimeStr: string) => {
    const targetTime = timeStrToTimeInteger(targetTimeStr);
    const progressHistories = timetableRow.progressHistories.filter((progressHistory) => {
      if (!progressHistory.isSimulated) {
        return true;
      }
      if (progressHistory.startTime < targetTime) {
        return true;
      }
      return false;
    });
    const lastProgressHistory = progressHistories[progressHistories.length - 1];
    if (isExist(lastProgressHistory) && lastProgressHistory.isSimulated && targetTime < lastProgressHistory.endTime) {
      // lastProgressHistory.endTimeをtargetTimeに変更し、ProgressHistoryの区間を変形する
      // 変形したProgressHistoryで行われるであろう作業量を計算し、newQuantityとして保持する
      const newQuantity = getTotalQuantityWithinPeriod(timetableRow, lastProgressHistory.startTime, targetTime);
      // lastProgressHistory.totalQuantity - lastProgressHistory.quantity が、変形対象となるProgressHistoryより前の時間帯の総作業量
      // ここに変形後の新しい区間で行われる想定作業量を加算すると、変形後に対応した総作業量が算出できる
      lastProgressHistory.totalQuantity += newQuantity - lastProgressHistory.quantity;
      lastProgressHistory.quantity = newQuantity;
      lastProgressHistory.endTime = targetTime;
    }
    timetableRow.progressHistories = progressHistories;
    if (
      isExist(timetableRow.header.simulatedCompletionTime) &&
      targetTime < timetableRow.header.simulatedCompletionTime
    ) {
      timetableRow.header.simulatedCompletionTime = null;
    }
  };

  /**
   * 期間内の合計数量を計算する
   */
  const getTotalQuantityWithinPeriod = (
    timetableRow: DailySimulationTimetableRow,
    startTime: TimeInteger,
    endTime: TimeInteger,
  ) => {
    if (endTime < startTime) {
      return 0;
    }
    const startTimeBlock = getTimeBlock(timetableRow.hourBlocks, startTime);
    if (!isExist(startTimeBlock)) {
      return 0;
    }
    const totalHeadcount = calculateTotalHeadcount(0, startTimeBlock, endTime);
    return (totalHeadcount * (timetableRow.header.planedProductivity ?? 0)) / state.blockLengthPerHour;
  };

  const getTimeBlock = (
    hourBlocks: DailySimulationHourBlock[],
    targetTime: number | string,
  ): DailySimulationTimeBlock | undefined => {
    let hour, min, timeBlockIndex;
    if (typeof targetTime === 'string') {
      [hour, timeBlockIndex] = getHourAndBlockIndex(targetTime);
    } else {
      [hour, min] = unpackTimeInteger(targetTime);
      timeBlockIndex = Math.round(min / state.unitMinutes);
    }
    return hourBlocks[hour].unitTimeBlocks[timeBlockIndex];
  };

  const calculateTotalHeadcount = (
    totalHeadcount: number,
    currentTimeBlock: DailySimulationTimeBlock,
    endTime: TimeInteger,
  ): number => {
    if (!isExist(currentTimeBlock)) {
      return totalHeadcount;
    }
    if (timeStrToTimeInteger(currentTimeBlock.displayTime) >= endTime) {
      return totalHeadcount;
    }
    const nextHeadcount = getNextTimeBlock(currentTimeBlock);
    if (!isExist(nextHeadcount)) {
      return totalHeadcount;
    }

    return calculateTotalHeadcount(totalHeadcount + currentTimeBlock.headcount, nextHeadcount, endTime);
  };

  const isPreviousSimulated = (currentTimeBlock: DailySimulationTimeBlock) => {
    const previousTimeBlock = getPreviousTimeBlock(currentTimeBlock);
    if (!isExist(previousTimeBlock)) {
      return false;
    }
    return previousTimeBlock.isSimulated;
  };

  const isHeadcountSameAsPreviousTime = (currentTimeBlock: DailySimulationTimeBlock) => {
    const previousTimeBlock = getPreviousTimeBlock(currentTimeBlock);
    if (!isExist(previousTimeBlock)) {
      return false;
    }
    return previousTimeBlock.headcount === currentTimeBlock.headcount;
  };

  const getPreviousTimeBlock = (currentTimeBlock: DailySimulationTimeBlock): DailySimulationTimeBlock | null => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    if (!isExist(hourBlocks)) {
      return null;
    }
    let [hour, timeBlockIndex] = getHourAndBlockIndex(currentTimeBlock);
    hour = timeBlockIndex === 0 ? hour - 1 : hour;
    timeBlockIndex = (timeBlockIndex + state.blockLengthPerHour - 1) % state.blockLengthPerHour;
    return hourBlocks[hour]?.unitTimeBlocks[timeBlockIndex] ?? null;
  };

  const getNextTimeBlock = (currentTimeBlock: DailySimulationTimeBlock) => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    if (!isExist(hourBlocks)) {
      return null;
    }
    let [hour, timeBlockIndex] = getHourAndBlockIndex(currentTimeBlock);
    hour = timeBlockIndex === state.blockLengthPerHour - 1 ? hour + 1 : hour;
    timeBlockIndex = (timeBlockIndex + 1) % state.blockLengthPerHour;
    return hourBlocks[hour]?.unitTimeBlocks[timeBlockIndex] ?? null;
  };

  const getHourAndBlockIndex = (target: DailySimulationTimeBlock | string) => {
    const targetTime = typeof target === 'string' ? target : target.displayTime;
    const [hourStr, minutesStr] = targetTime.split(':');
    return [Number(hourStr), Math.round(Number(minutesStr) / state.unitMinutes)];
  };

  const getTimeInteger = (timeBlock: DailySimulationTimeBlock) => {
    const [hour, timeBlockIndex] = getHourAndBlockIndex(timeBlock);
    return packToTimeInteger(hour, timeBlockIndex * state.unitMinutes, 0);
  };

  const getBlockProductivity = (timetableRow: DailySimulationTimetableRow) => {
    const productivity = timetableRow.header.planedProductivity ?? 0;
    return productivity / state.blockLengthPerHour;
  };

  /**
   * 進捗履歴のスタイルを生成して返す
   */
  const getProgressHistoriesStyle = (progressHistories: ProgressHistory[]) => {
    if (progressHistories.length === 0) {
      return { display: 'grid' };
    }

    const startHour = timeIntegerToHours(progressHistories[0].startTime);
    const emptyGridWidth = startHour * DAILY_SIMULATION_COLUMN_WIDTH;
    const gridWidths = [emptyGridWidth];
    let lastEndHour = 0;
    gridWidths.push(
      ...progressHistories.map(({ startTime, endTime }) => {
        const startHour = timeIntegerToHours(startTime);
        const endHour = timeIntegerToHours(endTime);
        lastEndHour = endHour;
        return DAILY_SIMULATION_COLUMN_WIDTH * (endHour - startHour);
      }),
    );
    const isFilledColumns = lastEndHour === DISPLAY_HOUR_PERIOD;

    return {
      display: 'grid',
      gridTemplateColumns: gridWidths.map((item) => `${item}px`).join(' ') + (isFilledColumns ? ' 0px' : ' auto'),
    };
  };

  /**
   * タイムラインを再取得してシミュレーションをし直す
   */
  const fetchAndSimulate = async (option = { isResetHeader: true, isResetHeadcount: true }) => {
    isResetHeader.value = option.isResetHeader;
    resetHeadcountTargetIds.value = option.isResetHeadcount ? allTimetableMasterIds.value : [];
    await fetchProgressHeaders();
    await setBudgetGroupPlanBoardMisc();
  };

  /**
   * シミュレーション結果を設定し直す
   * その際にシミュレーション用の設定などは初期化しない
   */
  const resetSimulation = () => {
    resetTimetable(isResetHeader.value, resetHeadcountTargetIds.value);
    filterTimetable(state.timetable);
    simulateTimetable('0:00', resetHeadcountTargetIds.value);
    isNoSimulate.value = false;
    isResetHeader.value = false;
    resetHeadcountTargetIds.value = [];
  };

  /**
   * 「当日生産性」を「シミュレーション生産性」にセットする
   */
  const setTotalProductivityToPlanedProductivity = (targetTimetableRows: DailySimulationTimetableRow[]) => {
    targetTimetableRows.forEach((timetableRow) => {
      if (timetableRow.header.totalProductivity === null || timetableRow.header.totalProductivity === 0) {
        return;
      }
      timetableRow.header.planedProductivity = Number(timetableRow.header.totalProductivity.toFixed(1));
    });
  };

  /**
   * タイムラインを再取得して、シミュレーションはしない
   */
  const fetchAndNoSimulate = async () => {
    isNoSimulate.value = true;
    fetchProgressHeaders();
  };

  /**
   * 特定のタイムラインを再取得してシミュレーションをし直す
   */
  const fetchAndSimulateTimetableRow = async (timetableMasterId: number) => {
    resetHeadcountTargetIds.value = [timetableMasterId];
    fetchProgressHeaders();
  };

  /**
   * 指定した時間が、第二引数で指定した時間帯に含まれているかを判定する
   */
  const isContainTimeRange = (targetTime: TimeInteger, timeRange: TimeRange) => {
    if (!isExist(timeRange.startTime) || !isExist(timeRange.endTime)) {
      return false;
    }
    return timeRange.startTime <= targetTime && targetTime < timeRange.endTime;
  };

  /**
   * 指定した時間が休憩時間かどうか判定する
   */
  const isBreakTime = (displayTime: string) => {
    const targetTime = timeStrToTimeInteger(displayTime);
    return (
      isContainTimeRange(targetTime, commonSetting.break1TimeRange) ||
      isContainTimeRange(targetTime, commonSetting.break2TimeRange)
    );
  };

  const waitLoading = (): void => {
    state.isLoading = true;
  };

  const finishLoading = (): void => {
    state.isLoading = false;
  };

  provide({
    state: readonly(state),
    displayedTimetable,
    timetableRowStyle,
    simulateTimetable,
    calculateRemainingTimeBlocks,
    updateHeadcountAndSimulateTimetableRow,
    getProgressHistoriesStyle,
    fetchAndSimulate,
    fetchAndNoSimulate,
    fetchAndSimulateTimetableRow,
    resetSimulation,
    setTotalProductivityToPlanedProductivity,
    isBreakTime,
    isPreviousSimulated,
    isHeadcountSameAsPreviousTime,
    saveProgressDetailCandidate,
    waitLoading,
    finishLoading,
  });
}

export function useDailySimulation(): InjectionValue {
  return inject();
}
