
import { defineComponent, inject, ref } from '@vue/composition-api';

import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { DisplayConditionStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import { timeStrToTimeInteger, formatDate } from 'src/util/datetime';
import { useUploadShiftCsv } from '../../hooks/useUploadShiftCsv';
import { useCreateShiftCsvTemplate } from '../../hooks/useCreateShiftCsvTemplate';
import CsvUploadNeutralButton from 'src/components/UIComponents/Buttons/CsvUploadButton/CsvUploadNeutralButton.vue';
import { SHIFT_PHASE, type ShiftPhase, ShiftDisplayModeSelectOptions } from 'src/consts';
import { ManHoursStateKey } from '../../hooks/useManHours';
import { BudgetGroupPlanBoardMiscsStateKey } from '../../hooks/useBudgetGroupPlanBoardMiscs';

export default defineComponent({
  name: 'regular-shift-header',
  components: {
    CsvUploadNeutralButton,
  },
  setup() {
    const commonState = inject(CommonStateKey);
    const staffsWithShiftsState = inject(StaffWithShiftsStateKey);
    const modalState = inject(ModalDisplayStateKey);
    const DisplayConditionState = inject(DisplayConditionStateKey);
    const ManHoursState = inject(ManHoursStateKey);
    const budgetGroupPlanBoardMiscsState = inject(BudgetGroupPlanBoardMiscsStateKey);
    if (
      !commonState ||
      !staffsWithShiftsState ||
      !modalState ||
      !DisplayConditionState ||
      !ManHoursState ||
      !budgetGroupPlanBoardMiscsState
    ) {
      throw new Error('State error');
    }
    const {
      baseDate,
      budgetGroup,
      budgetGroups,
      workplaceId,
      displayMode,
      isLoading,
      getMacroOperations,
      waitLoading,
      finishLoading,
    } = commonState;

    const { onClickShiftDisplayButton, isDetailDisplayConditionValid, shouldShowDisabled } = staffsWithShiftsState;

    const { openBulkCreateModal, openReceptionShiftModal, openUpdateMonthlySummaryModal, openShiftCsvOutputModal } =
      modalState;

    const {
      resetDisplayCondition,
      checkIsSetDisplayCondition,
      staffName,
      macroOperationMaster,
      staffAgency,
      overTimeAt7Days,
      isMoreOverTimeAt7Days,
      overTimeAtMonth,
      isMoreOverTimeAtMonth,
      wageAtMonth,
      isMoreWageAtMonth,
      targetDate,
      shiftType,
      attendanceType,
      startTime,
      endTime,
      workingTime,
      isMoreWorkingTime,
    } = DisplayConditionState;

    const { loadManHoursByMacroOperation } = ManHoursState;

    const { loadBudgetGroupPlanBoardMiscs } = budgetGroupPlanBoardMiscsState;

    const selectedDisplayMode = ref<ShiftPhase>(SHIFT_PHASE.PROVISIONAL);

    const displayShift = async (): Promise<void> => {
      waitLoading();
      await getMacroOperations();
      resetDisplayCondition();
      await onClickShiftDisplayButton(workplaceId.value, baseDate.value, budgetGroup.value.id);

      displayMode.value = selectedDisplayMode.value;

      await loadManHoursByMacroOperation();
      await loadBudgetGroupPlanBoardMiscs();
      isDetailDisplayConditionValid.value = checkIsSetDisplayCondition();
      finishLoading();
    };

    const clickShiftTableForPrint = (): void => {
      const startTimeFormatted =
        startTime.value.hour !== null && startTime.value.min !== null
          ? `${startTime.value.hour}:${startTime.value.min}`
          : '';
      const startTimeInt = startTimeFormatted ? timeStrToTimeInteger(startTimeFormatted) : null;
      const endTimeFormatted =
        endTime.value.hour !== null && endTime.value.min !== null ? `${endTime.value.hour}:${endTime.value.min}` : '';
      const endTimeInt = endTimeFormatted ? timeStrToTimeInteger(endTimeFormatted) : null;

      const searchParams = {
        base_date: formatDate(baseDate.value, 'yyyy-MM-dd'),
        date_for_shift_search: targetDate.value
          ? formatDate(targetDate.value, 'yyyy-MM-dd')
          : formatDate(baseDate.value, 'yyyy-MM-dd'),
        budget_group_id: budgetGroup.value.id,
        workplace_id: workplaceId.value,
        should_show_disabled: shouldShowDisabled.value,
        display_mode: displayMode.value,
        staff_name: staffName.value,
        macro_operation_master_id: macroOperationMaster.value?.id ?? null,
        staff_agency_id: staffAgency.value?.id ?? null,
        over_time_at_7days: overTimeAt7Days.value,
        is_more_over_time_at_7days: isMoreOverTimeAt7Days.value.value,
        over_time_at_month: overTimeAtMonth.value,
        is_more_over_time_at_month: isMoreOverTimeAtMonth.value.value,
        waage_at_month: wageAtMonth.value,
        is_more_wage_at_month: isMoreWageAtMonth.value.value,
        shift_type: shiftType.value.value,
        attendance_type: attendanceType.value?.value ?? null,
        start_time: startTimeInt,
        end_time: endTimeInt,
        working_time: workingTime.value,
        is_more_working_time: isMoreWorkingTime.value.value,
      };

      const queryStr: string[] = [];
      Object.entries(searchParams).forEach(([k, v]) => {
        if (v === null) {
          return;
        }
        queryStr.push(`${k}=${v}`);
      });

      window.open(`/print/workplace/${workplaceId.value}/regular-shift?${queryStr.join('&')}`);
    };

    const { uploadShiftCsv } = useUploadShiftCsv({ budgetGroup, dt: baseDate });
    const { createShiftCsvTemplate } = useCreateShiftCsvTemplate();

    return {
      selectedDisplayMode,
      baseDate,
      budgetGroup,
      budgetGroups,
      displayShift,
      displayMode,
      isLoading,
      ShiftDisplayModeSelectOptions,
      shouldShowDisabled,
      openBulkCreateModal,
      openReceptionShiftModal,
      openUpdateMonthlySummaryModal,
      openShiftCsvOutputModal,
      clickShiftTableForPrint,
      uploadShiftCsv,
      createShiftCsvTemplate,
    };
  },
});
